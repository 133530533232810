import React, { createContext, useContext, useEffect, useState } from 'react';
import { View, Linking } from "react-native"
import { AdServerApi, AdServerHelpers } from './api';
import type { AdPlacementProps, AdTypeProps, CompanyAdvertisementProps } from '../types';
import CompanyAdCard from './components/CompanyAdCard';
import type { BEEventProps } from '../Observer/api/types';


type AdServerProps = {
    placement:string,
    ad_type:string,
    role?:string,
    device_width:number,
    onViewAd: (be_event:BEEventProps) => void,
    onClickAd: (be_event:BEEventProps) => void,
}

const AdContext = createContext({
    loading:false,
    ads_loaded: false as boolean,
    role: undefined as string | undefined,
    ad_types:[] as AdTypeProps[],
    ad_placements: [] as AdPlacementProps[],
    distinct_id: undefined as string | undefined,
    session_id: undefined as string | undefined
})

type AdProviderProps = {
    children:any,
    role?:string,
    distinct_id?:string,
    session_id?:string
}

export const AdProvider = ({ children, role, distinct_id, session_id }:AdProviderProps) => {
    const [ ads_loaded, setAdsLoaded ] = useState(false);
    const [ ads_data, setData ] = useState<{
        loading:boolean,
        role:string | undefined,
        ad_types:AdTypeProps[],
        ad_placements:AdPlacementProps[],
    }>({
        loading:false,
        ad_types: [],
        role:undefined,
        ad_placements:[],
    })


    useEffect(() => {
        if(!ads_loaded){ return }
        setData({ ...ads_data, role })
    },[role])

    useEffect(() => {
        AdServerApi.setEnvironment();
        getAds()
    },[])

    const getAds = async() => {
        const ad_plcm = await AdServerApi.findAds();
        const typs = await AdServerApi.getAdTypes();
        setData({
            ...ads_data,
            ad_types:typs,
            role,
            ad_placements:ad_plcm
        })
        setAdsLoaded(true)
    }


    return (
        <AdContext.Provider value={{ ...ads_data, ads_loaded, distinct_id, session_id }}>
            {children}
        </AdContext.Provider>
    )
}

const AdServer = ({ placement, device_width, ad_type, role, onViewAd, onClickAd }:AdServerProps) => {
    const [ ad, setAd ] = useState<{
        company_ad?:CompanyAdvertisementProps,
        active_type?:AdTypeProps,
        active_placement?:AdPlacementProps
    }>({});
    const { company_ad, active_type, active_placement } = ad;
    const data = useContext(AdContext);

    
    useEffect(() => {
        if(!data?.ads_loaded){ return }
        if(!placement || !ad_type){ return }        
        getNewAd()
    },[data?.ads_loaded, placement, ad_type])

    const getNewAd = () => {
        let my_role = role;
        if(!my_role){ role = data.role }
        if(my_role == 'premium'){ return }
        const active_type = data.ad_types.find(t => t.ad_type == ad_type)
        const active_placement = data.ad_placements.filter(p => p.ad_buys && p.ad_buys.length > 0).find(p => p.placement == placement)
        if(!active_placement?.ad_buys){ return }
        const ad_buys_with_ads = active_placement.ad_buys.filter(b => b.company_ads && b.company_ads.length > 0)
        const active_ad_buy = ad_buys_with_ads[Math.floor(Math.random()*(ad_buys_with_ads.length-1))]
        if(!active_ad_buy){ return  }
        let selectedable_ads = active_ad_buy.company_ads
        if(!selectedable_ads || selectedable_ads.length == 0){ return  }
        selectedable_ads = selectedable_ads.filter(ad => ad.ad_type_id == active_type?.ad_type_id);
        const new_ad = selectedable_ads[Math.floor(Math.random()*(selectedable_ads.length-1))]
        if(!new_ad){ return }
        setAd({
            company_ad: new_ad,
            active_type,
            active_placement
        })
    }
    
    
    if(!company_ad || !active_type || !active_placement){ return <></> }

    return (
            <View>
                <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                    <CompanyAdCard
                        company_ad={company_ad}
                        ad_type={active_type}
                        size={{ width:device_width, height:200 }}
                        onViewAd={(ad) => {
                            onViewAd({
                                event_name:'ad_view',
                                level:2,
                                event_data: {
                                    ad_placement_id:active_placement.ad_placement_id,
                                    company_ad_buy_id: ad.company_ad_buy_id,
                                    company_ad_id: ad.company_ad_id,
                                    company_id: ad.company_id,
                                    ad_campaigns: ad.ad_campaigns
                                    
                                }
                            })
                        }}
                        onClickAd={(ad) => {
                            onClickAd({
                                event_name:'ad_click',
                                level:1,
                                event_data: {
                                    ad_placement_id:active_placement.ad_placement_id,
                                    company_ad_buy_id: ad.company_ad_buy_id,
                                    company_ad_id: ad.company_ad_id,
                                    company_id: ad.company_id,
                                    ad_campaigns: ad.ad_campaigns
                                    
                                }
                            })
                            const new_url = AdServerHelpers.generateUrl(ad.landing_url, data.distinct_id)
                            Linking.openURL(new_url)
                        }}
                    />
                </View>
            </View>
    )
}

export default AdServer