import React, { useState } from 'react';
import { FlatList, Image, TouchableOpacity, View } from "react-native"
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, EventProps, LeagueProps, MarketProps, MatchProps, MyPlayerProps, TeamProps, TournamentProps } from '../../types';
import Colors from '../../constants/colors';
import { Button, Icons, Switch, Text } from '../../Components';
import CompetitionMatchMarketCard from './CompetitionMatchMarketCard';
import moment from 'moment-mini';
import { view_styles } from '../../constants/styles';



type CompetitionContestsFormProps = {
    width:number,
    action_loading:boolean,
    is_valid?:boolean,
    competition:CompetitionProps,
    player?:MyPlayerProps,
    markets:MarketProps[],
    leagues:LeagueProps[],
    primary_markets:{ market:MarketProps, included:boolean}[],
    events:EventProps[],
    tournaments:TournamentProps[],
    matches:MatchProps[],
    athletes:AthleteProps[],
    teams:TeamProps[],
    competition_matches: CompetitionMatchProps[],
    competition_match_markets:CompetitionMatchMarketProps[],
    onShowContests:() => void,
    onAddPrimaryMarket:(market:MarketProps) => void,
    onRemovePrimaryMarket:(market:MarketProps) => void,
    onShowAthletes:(contest_id:string, contest_type:string, market_id:string) => void,
    onShowMarkets:(contest_id:string, contest_type:string) => void, 
    onDeleteMarket:(contest_id:string, contest_type:string, market:MarketProps) => void,
    onSaveCompetitionMatch:(competition_id:string, event_id:string, event_type:string, match_type:string, event:EventProps, market_id_override?:string, side_type_override?:string, side_id_override?:string) => void,
    onUpdateCompetitionMatch:(cm:CompetitionMatchProps) => void,
    onSaveCompetitionMatchMarkets: (cmms:CompetitionMatchMarketProps[]) => void,
    onDeleteCompetitionMatchMarkets:(cmms:CompetitionMatchMarketProps[]) => void,
    onDeleteCompetitionMatch: (competition_match_id:string) => void,
    onUpdateCompetition:(competition:CompetitionProps) => void
}


const CompetitionContestsForm = ({
    is_valid,
    competition,
    width,
    primary_markets,
    events,
    athletes,
    markets,
    player,
    leagues,
    competition_matches,
    competition_match_markets,
    onDeleteMarket,
    onRemovePrimaryMarket,
    onDeleteCompetitionMatch,
    onDeleteCompetitionMatchMarkets,
    onShowAthletes,
    onShowContests,
    onShowMarkets,
    onAddPrimaryMarket,
    onUpdateCompetition
}:CompetitionContestsFormProps) => {
    const [ expanded, setExpanded ] = useState(true);
    const [ show_match_markets, setShowMatchMarkets ] = useState<string[]>([]);
    const [ active_contest_type ] = useState('team');
    const [ expanded_contests, setExpandedContests ] = useState<string[]>([]);

    const unique_team_events = [ ...new Set(competition_matches.filter(cm => cm.event_type == 'team').map(cm => cm.event_id)) ]
    const append_to_events = competition.linked_event_ids && competition.linked_event_ids.length == unique_team_events.length ? true : false 

    const renderPrimaryMarkets = (data: { item:{ market:MarketProps, included:boolean }, index:number }) => {
        return (
            <View style={{ padding:10, width:100, borderRadius:8, backgroundColor:Colors.shades.white, margin:5, ...view_styles.float }}>
                <Text style={{ marginBottom:10 }} theme='header'>{data.item.market.type}</Text>
                <Switch
                    switch_type='on_off'
                    value={data.item.included}
                    onChange={(val) => val ? onAddPrimaryMarket(data.item.market) : onRemovePrimaryMarket(data.item.market)}
                />
            </View>
        )
    }
    
    const renderMarkets = (data: { item:string, index:number, event_id:string, event_type:string }) => {
        const market = markets.find(m => m.market_id == data.item);
        if(!market){ return <></> }
        const show_details = show_match_markets.includes(`${data.event_type}:${data.event_id}:${data.item}`) ? true : false

        return (
            <TouchableOpacity style={{ flex:1, borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600, padding:10, margin:3,  minWidth:125, backgroundColor:show_details ? Colors.brand.midnight : Colors.shades.white }}
            onPress={() => {
                if(show_details){ return setShowMatchMarkets(show_match_markets.filter(smm => smm != `${data.event_type}:${data.event_id}:${data.item}`)) }
                return setShowMatchMarkets(show_match_markets.concat(`${data.event_type}:${data.event_id}:${data.item}`))
            }}>
                <View style={{ flexDirection:'row' }}>
                    <Button
                        title='X'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.error}
                        onPress={async() => {
                            const confirmed = await confirm('Are you sure you want to delete this market?')
                            if(!confirmed){ return }
                            onDeleteMarket(data.event_id, data.event_type, market);
                        }}
                    />
                    <View style={{ flex:1 }}>
                        <Text size={14} color={show_details?Colors.shades.white:Colors.brand.midnight} weight="bold" textAlign="center">{market.stat_label ?? market.type}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={show_details?Colors.shades.white:Colors.brand.midnight} textAlign="center">{market.description}</Text>
                    </View>
                </View>
                {market.level == 'athlete' && show_details ?
                <Button
                    title="ADD ATHLETE"
                    style={{ marginTop:5 }}
                    padding={10}
                    title_color={Colors.brand.electric}
                    backgroundColor={Colors.shades.white}
                    onPress={() => {
                        onShowAthletes(data.event_id, data.event_type, data.item);
                    }}
                />
                :<></>}
            </TouchableOpacity>
                
        )
    }

    
    const showDetails = (show_event_id:string, show_event_type:string) => {
        
        let show_cms:CompetitionMatchProps[] = []
        show_match_markets.map(smm => {
            let split_smm = smm.split(':')
            let event_type = split_smm[0]
            let event_id = split_smm[1]
            let market_id = split_smm[2]
            if(event_id != show_event_id){ return }
            if(show_event_type != event_type){ return }
            const market = markets.find(m => m.market_id == market_id);
            if(!market){ return }
            if(market.level == 'event' && market.primary_market){
                const primary_cm = competition_matches.find(cm => cm.event_id == event_id && cm.event_type == event_type && !cm.market_id_override);
                if(!primary_cm){ return }
                const primary_cmms = competition_match_markets.filter(cmm => cmm.competition_match_id == primary_cm?.competition_match_id && cmm.market_id == market_id);
                show_cms.push({ ...primary_cm, competition_match_markets:primary_cmms })
            }
            if(market.level == 'athlete'){
                const cms = competition_matches.filter(cm => cm.event_id == event_id && cm.event_type == event_type && cm.market_id_override == market_id)
                cms.map(cm => {
                    let cmm = competition_match_markets.filter(cmm => cmm.competition_match_id == cm.competition_match_id);
                    //if(cmm.length == 2){
                        show_cms.push({ ...cm, competition_match_markets: cmm })
                    //}
                })
            }
        })
            
        return show_cms
    }
       

    const getMatchTitle = (cm:CompetitionMatchProps, market:MarketProps):{image_url?:string, header:string, description:string} => {
        
        let error = { header: 'Market', description:market.description }
        switch(cm.event_type){
            case 'team':
                const event = events.find(e => e.event_id == cm.event_id)
                if(!event){ return error }
                if(!cm.market_id_override){
                    //This is a primary one that we can just do the market name and event title
                    return { header: event.event_title, description: market.description }
                }
                switch(cm.side_type_override){
                    case 'athlete':
                        const athlete = athletes.find(a => a.athlete_id == cm.side_id_override)
                        if(!athlete){ return error }
                        return { image_url:athlete.image?.url, header: `${athlete.first_name} ${athlete.last_name} ${market.stat_label}`, description:market.description }
                    default: return error
                }
            default: return error
        }
        
    }
    

    const renderCompetitionMatches = (data: { item:CompetitionMatchProps, index:number }) => {
        if(!data.item.competition_match_markets){ return <></> }
        const market = markets.find(m => data.item.competition_match_markets && m.market_id == data.item.competition_match_markets[0]?.market_id)
        if(!market){ return <></> }

        const { image_url, header, description } = getMatchTitle(data.item, market)
        return (
            <View style={{ padding:10, flexDirection:'row', alignItems:'center', borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <Button
                    title="X"
                    style={{ marginRight:5 }}
                    borderWidth={0}
                    title_size={12}
                    title_color={Colors.utility.error}
                    onPress={() => {
                        if(data.item.market_id_override){
                            return onDeleteCompetitionMatch(data.item.competition_match_id)
                        }
                        return onDeleteCompetitionMatchMarkets(data.item.competition_match_markets ?? [])
                    }}
                />
                {image_url ?
                <View style={{ padding:5 }}>
                    <Image
                        source={{ uri:image_url }}
                        style={{ marginRight:10, height:40, width:40, borderRadius:100 }}
                        resizeMode="center"
                    />
                </View>
                :<></>}
                <View style={{ flex:1 }}>
                    <Text size={14} weight='bold'>{header}</Text>
                    <Text style={{ marginTop:3 }} size={12} weight='bold'>{description}</Text>
                </View>
                <CompetitionMatchMarketCard
                    event_id={data.item.event_id}
                    event_type={data.item.event_type}
                    competition_match_id={data.item.competition_match_id}
                    competition_match_markets={data.item.competition_match_markets}
                    competition_summaries={[]}
                    market={market}
                    onSaveMarkets={(cmms) => console.log(cmms)}
                    onUpdateMarkets={(cmms) => console.log(cmms)}
                    onGenerateMarkets={() => console.log('Blah')}
                />
            </View>
        )
    }
        
    const renderTeamEvents = (data: { item:string, index:number }) => {
        const event = events.find(e => e.event_id == data.item);
        if(!event){ return <></> }
        if(!event.supported_markets){ return <></> }
        const cms = competition_matches.filter(cm => cm.event_id == data.item && cm.event_type == 'team');
        const cmms = competition_match_markets.filter(cmm => cms.map(cm => cm.competition_match_id.toString()).includes(cmm.competition_match_id));
        const unique_market_ids = [ ...new Set(cmms.map(cmm => cmm.market_id).concat(cms.filter(cm => cm.market_id_override).map(cm => cm.market_id_override ?? '0'))) ]
        const league = leagues.find(l => l.league_id == event.league_id);
        const visible_matches = showDetails(data.item, 'team')
        const expanded = expanded_contests.includes(`${event.event_id}:team`) ? true : false
        return (
            <View style={{ margin:5,  backgroundColor:Colors.shades.white, zIndex: data.index * -1, maxWidth:width }}>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:15, zIndex:1}} onPress={() => {
                    if(expanded){ return setExpandedContests(expanded_contests.filter(c => c != `${event.event_id}:team`)) }
                    else { return setExpandedContests(expanded_contests.concat(`${event.event_id}:team`)) }
                }}>
                    {['pending','paused'].includes(competition.status) && cms.length == 1 && cmms.length == 0 ? 
                    <Button
                        title="X"
                        title_color={Colors.utility.error}
                        borderRadius={8}
                        borderWidth={1}
                        borderColor={Colors.utility.error}
                        onPress={() => onDeleteCompetitionMatch(cms[0]?.competition_match_id ?? '')}
                    />
                    :<></>}
                    <View style={{ flex:1, marginLeft:5, flexDirection:'row', alignItems:'center' }}>
                        {league?.league_image ?
                        <Image
                            source={{ uri: league.league_image }}
                            style={{ height:30, width:30, marginRight:10 }}
                            resizeMode="cover"
                        />
                        :<></>}
                        <View style={{ flex:1 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>{event.away?.market_name} {event.away?.name} vs {event.home?.market_name} {event.home?.name}</Text>
                            <Text style={{ marginTop:4 }} size={12} color={Colors.brand.midnight}>{moment(event.scheduled_datetime).format('MMMM DD hh:mm a')}</Text>
                        </View>
                    </View>
                    <View style={{ marginLeft:5, marginRight:5, height:25, width:25, justifyContent:'center', alignItems:'center', borderRadius:100, backgroundColor:Colors.brand.electric }}>
                        <Text size={10} color={Colors.shades.white} weight='bold'>{unique_market_ids.length}</Text>
                    </View>
                    <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} size={8} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {expanded ?
                <View style={{ padding:10 }}>
                    <View style={{ flexDirection:'row', marginBottom:5 }}>
                        <Text style={{ flex:1 }} size={16} weight='bold'>Selected Markets</Text>
                        <Button
                            title="Add Markets"
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.midnight}
                            onPress={() => {
                                onShowMarkets(data.item, 'team');
                            }}
                        />
                    </View>
                     <View style={{ flexDirection:'row', flexWrap: 'wrap', padding:10 }}>
                        {unique_market_ids.map((market_id, index) => {
                            return (
                                renderMarkets({ item: market_id, index, event_id:data.item, event_type:'team' })
                            )
                        })}
                    </View>
                </View>
                :<></>}
                {visible_matches.length > 0 ?
                    <FlatList
                        data={visible_matches}
                        renderItem={renderCompetitionMatches}
                        keyExtractor={(item) => item.competition_match_id.toString()}
                    />
                :<></>}
            </View>
        )
    }




    return (
        <View style={{ ...view_styles.section, minWidth:350, flexGrow:1, maxWidth:width }}>
            <TouchableOpacity style={{ ...view_styles.section_header, maxWidth:width }} onPress={() => setExpanded(!expanded)}>
                {is_valid ?
                <Icons.CheckCirlceIcon size={16} color={Colors.utility.success} />
                :
                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                }
                <View style={{ flex:1, marginRight:10, marginLeft:10 }}>
                    <Text theme='header'>Competition Contests</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Competitions require contests for users to make picks / wagers.  Select 'Add Contests' to get started.</Text>
                </View>
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:0, backgroundColor:Colors.shades.shade100 }}>
                
                <View style={{ ...view_styles.section_header }}>
                    <TouchableOpacity style={{ flex:1 }}>
                        <Text theme='header'>Team Events</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Select team based events to include in this competition</Text>
                    </TouchableOpacity>
                    <Button
                        title='ADD'
                        padding={10}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        onPress={() => onShowContests()}
                    />
                </View>
                {player?.role == 'admin' ?
                <View style={{ ...view_styles.body_row, padding:20, backgroundColor:Colors.highlights.highlight200Faded }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header'>Append To Events</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Should the 'Pick 6' button show up on events that are included in this competition?</Text>
                    </View>
                    <Switch 
                        value={append_to_events}
                        onChange={(val) => {
                            if(val){ return onUpdateCompetition({ ...competition, linked_event_type: 'team', linked_event_ids: unique_team_events }) }
                            return onUpdateCompetition({ ...competition, linked_event_type: 'team', linked_event_ids: undefined }) 
                        }}
                        switch_type='on_off'
                    />
                </View>
                :<></>}
                <View style={{ padding:10 }}>
                    <Text style={{ padding:10 }} theme='header_2'>The following markets can be added to all events</Text>
                    <View style={{ padding:5 }}>
                        <FlatList
                            data={primary_markets}
                            renderItem={renderPrimaryMarkets}
                            horizontal
                            keyExtractor={(item) => item.market.market_id.toString()}
                        />
                    </View>
                </View>
                <View style={{ ...view_styles.section_body, padding:0 }}>
                    {active_contest_type == 'team' ?
                    <View>
                        {unique_team_events.map((id, index) => {
                            return renderTeamEvents({ item:id, index })
                        })}
                    </View>
                    :<></>}
                </View>
            </View>
            :<></>}
        </View>
    )
        
}

export default CompetitionContestsForm