import moment from "moment-mini";
import type { AthleteProps, EventOrderStatProps, EventProps, MarketProps, TradeProps } from "../../../../types"

export  { TeamEventMarketHelpers }
const TeamEventMarketHelpers = {
    getPrimaryMarkets: (event:EventProps, markets:MarketProps[]):MarketProps[] => {
        if(!event.supported_markets){ return [] }
        let event_markets = markets.filter(m => event.supported_markets?.filter(sm => !sm.removed).map(sm => sm.market_id.toString()).includes(m.market_id.toString()));
        return event_markets.filter(m => m.primary_market);
    },
    getNonPrimaryMarkets:(event:EventProps, markets:MarketProps[]):MarketProps[] => {
        if(!event.supported_markets){ return [] }
        let event_markets = markets.filter(m => event.supported_markets?.filter(sm => !sm.removed).map(sm => sm.market_id.toString()).includes(m.market_id.toString()));
        return event_markets.filter(m => !m.primary_market);
    },
    getTimeDetail: (event:EventProps) => {
        if(['scheduled','created','not_started'].includes(event.status)){ return moment(event.scheduled_datetime).format('MM/DD @ hh:mm a') }
        return event.time_detail
    },
    sortNonPrimaryMarkets: (markets:MarketProps[], event_order_stats:EventOrderStatProps[], latest_trades:TradeProps[]) => {
        let available = latest_trades.find(t => markets.map(m => m.market_id.toString()).includes(t.market_id)) ? true : false
        let sorted_stats = event_order_stats.filter(s => s.market_type == 'FOR_MONEY' && markets.map(m => m.market_id.toString()).includes(s.market_id.toString()));
        
        //Now need to combine event level markets
        let event_level_markets:EventOrderStatProps[] = []
        let non_event_level_markets:EventOrderStatProps[] = []
        sorted_stats.map(ss => {
            let market = markets.find(m => m.market_id == ss.market_id);
            if(!market){ return }
            if(market.level == 'event'){ return event_level_markets.push(ss) }
            return non_event_level_markets.push(ss)
        })
        let unique_ev_markets = [ ...new Set(event_level_markets.map(m => m.market_id)) ]
        let combined_ev_marktes:EventOrderStatProps[] = []
        unique_ev_markets.map(ss => {
            let find_one = event_level_markets.find(m => m.market_id == ss)
            if(!find_one){ return }
            let markets_need_combined = event_level_markets.filter(m => m.market_id == ss)
            let ss_market:EventOrderStatProps = {
                market_id: ss,
                open_order_count: markets_need_combined.reduce((a,b) => a + parseInt(b.open_order_count as string), 0),
                open_order_amount: markets_need_combined.reduce((a,b) => a + b.open_order_amount, 0),
                event_id: find_one.event_id,
                event_type: find_one.event_type,
                side_id: find_one.side_id,
                side_type: find_one.side_type,
                market_type: find_one.market_type
            }
            combined_ev_marktes.push(ss_market)
        })
        sorted_stats = non_event_level_markets.concat(combined_ev_marktes)


        return { non_primary_markets: sorted_stats.sort((a,b) => parseInt(b.open_order_count as string) - parseInt(a.open_order_count as string)), available }
    },
    sortAthleteMarkets: (market:MarketProps, event_order_stats:EventOrderStatProps[], latest_trades:TradeProps[], athletes:AthleteProps[]):AthleteProps[] => {
        if(!market){ return [] }
        let market_stats = event_order_stats.filter(s => s.market_id == market.market_id && s.market_type == 'FOR_MONEY');
        let market_trades = latest_trades.filter(t => t.market_type == 'FOR_MONEY' && t.market_id == market.market_id && t.side_type == 'athlete');
        let filtered_athletes = athletes.filter(a => market_trades.map(t => t.side_id.toString()).includes(a.athlete_id.toString()));
        return filtered_athletes.sort((a,b) => {
            let old_order_count = market_stats.filter(s => s.side_id == a.athlete_id).reduce((a,b) => a + parseInt(b.open_order_count as string), 0)
            let new_order_count = market_stats.filter(s => s.side_id == b.athlete_id).reduce((a,b) => a + parseInt(b.open_order_count as string), 0)
            return new_order_count - old_order_count
        })
    },
    getEventStatus: (event:EventProps):'active'|'closed'|'inactive' => {
        let status:'active'|'closed'|'inactive' = 'active'
        if(['postponed','cancelled','canceled','complete','completed','closed','suspended'].includes(event.status)){ status = 'closed' }
        if(status == 'active' && !event.is_active){ status = 'inactive' }
        return status
    }
}