import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View, Image, FlatList, TouchableOpacity, ActivityIndicator, ScrollView } from 'react-native';
import type { AthleteProps, BestAvailableOrderProps, CompanyProps, CompetitionProps, EventOrderStatProps, EventProps, MarketProps, MarketSideOptionProps, OrderProps, SquaresCompetitionProps, TradeProps } from '../../../types';
import { view_styles } from '../../../constants/styles';
import { Button, Icons, LinearDiagnal, Text } from '../../../Components';
import {TeamEventMarketHelpers} from './api';
import MarketButton from '../../components/MarketButton';
import Colors from '../../../constants/colors';
import AthleteMarket from '../../components/AthleteMarket';
import EventMarket from '../../components/EventMarket';

import { MarketComponentApi, MarketComponentHelpers } from '../../api';
import { MarketButtonHelpers } from '../MarketButton/api';
import LinearGradient from 'react-native-linear-gradient';
import { useIsInViewport } from '../../../Components/ViewportObserver';
import type { BEEventProps } from '../../../Observer/api/types';

type TeamEventMarketProps = {
    event:EventProps,
    markets:MarketProps[],
    init_expanded?:boolean,
    competitions?:CompetitionProps[],
    show_id?:string,
    squares_competition?:SquaresCompetitionProps,
    latest_trades:TradeProps[],
    best_available_orders:BestAvailableOrderProps[],
    event_order_stats:EventOrderStatProps[],
    default_price_view: 'last_trade'|'best_available',
    onView:(data:{ event_id:string, event_type:string, market_id:string, side_type?:string, side_id?:string }) => void,
    onOrder:(order:OrderProps) => void,
    onEvent?:(be_event:BEEventProps) => void,
    onExpand?:(expanded:boolean) => void,
    show_grades?:boolean,
    onCompetitionSelect:(competition_id:string) => void,
    onSquaresSelect: (sq_comp_id:string) => void,
    onViewAdditionalMarkets: (event_id:string, event_type:string) => void,
    onTradeLongPress:(trade:TradeProps) => void,
    onActivate?:(event_id:string, event_type:string) => void
    activate_loading?:boolean
}
const TeamEventMarket = ({ event, init_expanded, show_id, activate_loading, competitions, squares_competition, show_grades, markets, latest_trades, best_available_orders, event_order_stats, default_price_view, onSquaresSelect, onCompetitionSelect, onExpand, onOrder, onView, onViewAdditionalMarkets, onTradeLongPress, onActivate, onEvent }:TeamEventMarketProps) => {
    const [ expanded_data, setExpandedData ] = useState<{
        expanded:boolean,
        loaded:boolean,
        loading:boolean,
        athletes:AthleteProps[]
    }>({
        loaded:false,
        loading:false,
        expanded:false,
        athletes:[]
    })
    const [ sponsor, setSponsor ] = useState<CompanyProps|undefined>(undefined);
    const [ competitions_expanded, setCompetitionsExpanded ] = useState(false);
    const { expanded, athletes, loaded, loading } = expanded_data;
    const event_status = TeamEventMarketHelpers.getEventStatus(event);
    const ref = useRef(null)
    const isInViewport = useIsInViewport(ref)

    useEffect(() => {
        MarketComponentApi.setEnvironment();
        if(init_expanded){ expandedEvent(); }
        if(event.sponsor_id){ getCompanyFromServer(event.sponsor_id) }
    },[])

    useEffect(() => {
        if(isInViewport && onEvent && event.sponsor_id){
            let be_event:BEEventProps = {
                event_name:'company_view',
                event_data: {
                    view_type: 'contest',
                    view_location: 'list',
                    contest_type:'team',
                    contest_id:event.event_id,
                    company_id: event.sponsor_id
                },
                level:2
            }
            onEvent(be_event)
        }
    },[isInViewport])


    const getCompanyFromServer = async(company_id:string) => {
        const resp = await MarketComponentApi.getCompanyById(company_id)
        if(!resp?.company){ return }
        setSponsor(resp.company)
    }

    const expandedEvent = async() => {
        if(!event){ return }
        if(onExpand){ onExpand(expanded ? false : true) }
        if(expanded){ return setExpandedData({ ...expanded_data, expanded: false }) }
        //Check if we have any non-primary markets sorted

        if(loaded){ return setExpandedData({ ...expanded_data, expanded: true }) }
        setExpandedData({ ...expanded_data, expanded:true, loading:true });

        //Need to load our athletes in here!
        const away_athletes = await MarketComponentApi.getAthletesByTeamId(event.away_team_id);
        const home_athletes = await MarketComponentApi.getAthletesByTeamId(event.home_team_id);
        setExpandedData({
            expanded:true,
            loaded:true,
            loading:false,
            athletes: away_athletes.concat(home_athletes)
         })
    }
    
    const primary_markets = useMemo(() => {
        return TeamEventMarketHelpers.getPrimaryMarkets(event, markets)
    }, [markets.length])
    
    const handleSelectCompetition = () => {
        if(!competitions || competitions.length == 0){ return }
        if(competitions.length == 1 && competitions[0]){ return onCompetitionSelect(competitions[0].competition_id) }
        return setCompetitionsExpanded(true);
    }

    const renderCompetitions = (data:{ item:CompetitionProps, index: number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                setCompetitionsExpanded(false);
                onCompetitionSelect(data.item.competition_id)
            }}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:25, width:25 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header_2'>{data.item.competition_name}</Text>
                </View>
                <Icons.ChevronIcon direction='right' size={10} color={Colors.brand.midnight} />
            </TouchableOpacity>
        )
    }

    const renderSides = useCallback((data:{ item:MarketSideOptionProps, index:number, market:MarketProps, is_locked:boolean }) => {
        const ba_order = best_available_orders.find(o => o.market_id == data.market.market_id && o.side == data.item.side && o.show)
        const side_details = MarketComponentHelpers.getTeamEventSideIds(event, data.market, data.item);
        if(!side_details){ return <></> } //Bomb out if we cant get the side details
        let trade = MarketButtonHelpers.getVisibleTrade(default_price_view, data.market, data.item, latest_trades, best_available_orders, side_details.side_id, side_details.reversed_side_id);
        if(!trade){
            trade = MarketButtonHelpers.getTradeFromMarket(data.market, event.event_id, 'team', data.item.side, data.item.id_source, side_details.side_id, side_details.reversed_side_id)
        }
        if(!trade){ return <></> }
        return (
            <View style={{ margin:2, width:50, height:50 }}>
                <MarketButton
                    side_option={data.item}
                    market={data.market}
                    trade={trade}
                    order={ba_order}
                    locked={data.is_locked}
                    show_grades={show_grades}
                    onLongPress={onTradeLongPress}
                    onPress={(order) => {
                        const order_title = MarketComponentHelpers.getOrderTitleForTeamEvent(order, data.market, event);
                        if(!order_title){ return alert('Unable to process order') }
                        onOrder({ ...order, title: order_title })
                    }}
                />
            </View>
        )
    },[JSON.stringify(best_available_orders), JSON.stringify(latest_trades)])

    const renderMarketHeaders = useCallback((data:{ item:MarketProps, index:number }) => {
        return (
            <View style={{ width:50, marginLeft:2, marginRight:2 }}>
                <Text size={12} color={Colors.brand.midnight} textAlign='center'>{data.item.type.toUpperCase()}</Text>
            </View>
        )
    },[primary_markets.length])


    const renderNonPrimaryMarkets = useCallback((data: { item:EventOrderStatProps, index:number }) => {
        const market = markets.find(m => m.market_id == data.item.market_id);
        if(!market){ return <></> }
        switch(market.level){
            case 'athlete':
                const athlete = athletes.find(a => a.athlete_id == data.item.side_id)
                if(!athlete){ return <></> }
                const athlete_trades = latest_trades.filter(t => t.market_type == 'FOR_MONEY' && t.market_id == data.item.market_id && t.side_type == 'athlete' && t.side_id == data.item.side_id);
                const athlete_orders = best_available_orders.filter(t => t.market_id == data.item.market_id && t.side_type == 'athlete' && t.side_id == data.item.side_id);
                return (
                    <View>
                        <AthleteMarket
                            event={event}
                            event_type='team'
                            athlete={athlete}
                            show_grades={show_grades}
                            market={market}
                            event_order_stats={[data.item]}
                            latest_trades={athlete_trades}
                            onTradeLongPress={onTradeLongPress}
                            default_price_view={default_price_view}
                            best_available_orders={athlete_orders}
                            onOrder={onOrder}
                            onView={onView}
                        />
                    </View>
                )
            case 'event':
                const event_trades = latest_trades.filter(t => t.market_type == 'FOR_MONEY' && t.market_id == data.item.market_id);
                const event_orders = best_available_orders.filter(t => t.market_id == data.item.market_id);
                return (
                    <View>
                        <EventMarket
                            event={event}
                            market={market}
                            onTradeLongPress={onTradeLongPress}
                            event_order_stats={[data.item]}
                            show_grades={show_grades}
                            latest_trades={event_trades}
                            best_available_orders={event_orders}
                            default_price_view={default_price_view}
                            onOrder={onOrder}
                            onView={onView}
                        />
                    </View>
                )
            default: return<></>
        }
    },[athletes.length, JSON.stringify(best_available_orders), JSON.stringify(latest_trades)])

    const renderPrimaryMarkets = useCallback((data:{ item:MarketProps, index:number }) => {
        const is_locked = MarketComponentHelpers.isEventMarketLocked(event, data.item.market_id);
        return (
            <View>
                <FlatList
                    key={`${event.event_id}_side_options`}
                    data={data.item.side_options.sort((a,b) => {
                        let a_t = a.side == data.item.trade_side ? 1 : 0
                        let b_t = b.side == data.item.trade_side ? 1 : 0
                        return a_t - b_t
                    })}
                    keyExtractor={(item) => `${event.event_id}:${data.item.market_id}:${item.side}`}
                    renderItem={({ item, index }) => renderSides({ item, index, market:data.item, is_locked })}
                />
            </View>
        )
    },[JSON.stringify(best_available_orders), JSON.stringify(latest_trades)])

    if(!event.away || !event.home){ return <></> }

    const { liquidity, open_order_count } = MarketComponentHelpers.getLiqudity(event_order_stats);
    const { non_primary_markets, available } = useMemo(() => TeamEventMarketHelpers.sortNonPrimaryMarkets(TeamEventMarketHelpers.getNonPrimaryMarkets(event, markets), event_order_stats, latest_trades),[])

    return (
        <View ref={ref} style={{ ...view_styles.section, borderWidth:1, borderColor:Colors.shades.shade600 }}>
            {sponsor ?
            <LinearGradient style={{ marginTop:-1, marginLeft:-1, marginRight:-1, padding:10, flexDirection:'row', borderTopRightRadius:8, borderTopLeftRadius:8 }} start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[sponsor.brand_primary ?? Colors.brand.midnight, sponsor.brand_secondary ?? Colors.brand.cyan]}>
                <View style={{ height:30, width:30, borderRadius:4, backgroundColor:Colors.shades.white }}>
                    <Image
                        source={{ uri: sponsor.company_image?.url }}
                        style={{ height:30, width:30, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={12} color={Colors.shades.white} weight='bold'>Brought to you by {sponsor.company_name}</Text>
                    <View style={{ marginTop:3, flexDirection:'row', alignItems:'center' }}>
                        {show_id ?
                        <Text style={{ marginLeft:2, marginRight:3 }} size={12} color={Colors.shades.white} weight='semibold' selectable>{event.event_id} </Text>
                        :<></>}
                        {event.event_sub_title ?
                        <Text size={12} color={Colors.shades.white} weight='semibold'>{event.event_sub_title}</Text>
                        :<></>}
                    </View>
                </View>
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <Icons.TVIcon size={10} color={Colors.shades.white} />
                    <Text style={{ marginLeft:8 }} size={12} color={Colors.shades.white} weight='semibold'>{event.info?.broadcast?.network}</Text>
                </View>
                {onActivate && event_status != 'closed' ?
                <Button
                    title={event_status == 'active' ? 'Refresh' : 'Activate'}
                    style={{ opacity: activate_loading ? 0.5 : 1, marginLeft:5 }}
                    disabled={activate_loading}
                    loading={activate_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onActivate(event.event_id, 'team')}
                />
                :<></>}
            </LinearGradient>
            :
            <View nativeID='event_header_data' style={{ flexDirection:'row', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    {show_id ?
                    <Text style={{ marginLeft:2, marginRight:3 }} size={12} color={Colors.brand.midnight} weight='semibold' selectable>{event.event_id} </Text>
                    :<></>}
                    {event.event_sub_title ?
                    <Text size={12} color={Colors.brand.midnight} weight='semibold'>{event.event_sub_title}</Text>
                    :<></>}
                </View>
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <Icons.TVIcon size={10} color={Colors.brand.midnight} />
                    <Text style={{ marginLeft:8 }} size={12} color={Colors.brand.midnight} weight='semibold'>{event.info?.broadcast?.network}</Text>
                </View>
                {onActivate && event_status != 'closed' ?
                <Button
                    title={event_status == 'active' ? 'Refresh' : 'Activate'}
                    style={{ opacity: activate_loading ? 0.5 : 1, marginLeft:5 }}
                    disabled={activate_loading}
                    loading={activate_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onActivate(event.event_id, 'team')}
                />
                :<></>}
            </View>
            }
            <View nativeID='primary_markets' style={{ flexDirection:'row' }}>
                <View nativeID='team_details' style={{ flex:1, padding:5, paddingRight:0 }}>
                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:5, marginBottom:5, marginLeft:10 }}>
                        <View style={{ marginRight:5, height:10, width:10, borderRadius:100, backgroundColor:event_status=='active'?Colors.utility.success:event_status=='inactive'?Colors.brand.electric:Colors.utility.error }} />
                        <Text size={12} color={Colors.brand.midnight}>{TeamEventMarketHelpers.getTimeDetail(event)}</Text>
                    </View>
                    <View nativeID='away_details' style={{ ...view_styles.body_row, minHeight:48 }}>
                        <Image
                            source={{ uri: event.away.image?.url }}
                            style={{ height:30, width:30 }}
                            resizeMode='cover'
                        />
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginLeft:5 }}>
                            {event.away.rank ?
                            <Text style={{ marginRight:3 }} size={12} color={Colors.brand.cyan} weight='semibold'>{event.away.rank}</Text>
                            :<></>}
                            <View style={{ flex:1, marginRight:5 }}>
                                <Text theme='header_2'>{event.away.market_name} {event.away.name}</Text>
                                {event.away_sub_title ?
                                <Text style={{ marginTop:3 }} theme='body_2'>{event.away_sub_title}</Text>
                                :<></>}
                            </View>
                            <Text theme='body'>{event.away_team_score}</Text>
                        </View>
                    </View>
                    <View nativeID='home_details' style={{ ...view_styles.body_row, minHeight:48 }}>
                        <Image
                            source={{ uri: event.home.image?.url }}
                            style={{ height:30, width:30 }}
                            resizeMode='cover'
                        />
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginLeft:5 }}>
                            {event.home.rank ?
                            <Text style={{ marginRight:3 }} size={12} color={Colors.brand.cyan} weight='semibold'>{event.home.rank}</Text>
                            :<></>}
                            <View style={{ flex:1, marginRight:5 }}>
                                <Text theme='header_2'>{event.home.market_name} {event.home.name}</Text>
                                {event.home_sub_title ?
                                <Text style={{ marginTop:3 }} theme='body_2'>{event.home_sub_title}</Text>
                                :<></>}
                            </View>
                            <Text theme='body'>{event.home_team_score}</Text>
                        </View>
                    </View>
                </View>
                {event_status == 'active' ?
                <View nativeID='primary_market_details' style={{ padding:5 }}>
                    <View style={{ marginTop:5, marginBottom:5 }}>
                        <FlatList
                            key={`${event.event_id}_market_headers`}
                            data={primary_markets}
                            keyExtractor={(item) => item.market_id.toString()}
                            horizontal
                            renderItem={renderMarketHeaders}
                        />
                    </View>
                    <FlatList
                        key={`${event.event_id}_primary_markets`}
                        data={primary_markets.sort((a,b) => parseInt(a.market_id) - parseInt(b.market_id))}
                        horizontal
                        renderItem={renderPrimaryMarkets}
                        keyExtractor={(item) => item.market_id.toString()}
                    />
                </View>
                : 
                <TouchableOpacity disabled={!onActivate ? true: false} style={{ flex:0.5, padding:10, justifyContent:'center', alignItems:'center' }} onPress={() => onActivate ? onActivate(event.event_id, 'team') : console.log('')}>
                    <Icons.MarketClosedIcon size={60} color={Colors.brand.midnight} />
                </TouchableOpacity>
                }
            </View>
            {event_status == 'active' ?
            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, flexDirection:'row', alignItems:'center', flexWrap:'wrap', borderBottomLeftRadius:8, borderBottomRightRadius:8 }}>
                <TouchableOpacity style={{ flex:1, flexDirection:'row', minWidth:115 }} onPress={() => {
                    if(!primary_markets[0]){ return }
                    onView({ event_id: event.event_id, event_type:'team', market_id: primary_markets[0].market_id });
                }}>
                    <View style={{ backgroundColor:Colors.shades.white, height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                        <Icons.LiquidityIcon color={Colors.brand.midnight} size={30}/>
                    </View>
                    <View style={{ marginLeft:5 }}>
                        <Text theme='body_2'>Liquidity</Text>
                        <Text size={12} color={Colors.brand.electric} weight='semibold'>${liquidity.toFixed()} {open_order_count} Orders</Text>
                    </View>
                </TouchableOpacity>
                {squares_competition ?
                <TouchableOpacity 
                    style={{ marginRight:5, flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, backgroundColor:Colors.highlights.highlight100, ...view_styles.float }}
                    onPress={() => onSquaresSelect(squares_competition.sq_comp_id)}>
                    <Icons.SquaresIcon size={18} color={Colors.shades.white} />
                    <Text style={{ marginLeft:5 }} size={14} color={Colors.shades.white} weight='semibold'>Squares</Text>
                </TouchableOpacity>
                :<></>}
                {competitions && competitions.length > 0 ?
                <TouchableOpacity 
                    style={{ marginRight:5, flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, backgroundColor:Colors.highlights.highlight500, ...view_styles.float }}
                    onPress={() => handleSelectCompetition()}>
                    <Icons.PickEmIcon size={14} color={Colors.shades.white} />
                    <Text style={{ marginLeft:5 }} size={14} color={Colors.shades.white} weight='semibold'>MultiPick</Text>
                </TouchableOpacity>
                :<></>}
                {non_primary_markets.length > 0 ?
                <TouchableOpacity style={{ height:35, width:35, justifyContent:'center', borderRadius:100, alignItems:'center', backgroundColor:Colors.shades.white, ...view_styles.float }} onPress={() => expandedEvent()}>
                   <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} size={8} color={Colors.brand.midnight} />
                </TouchableOpacity>
                :available ?
                <Button
                    title='MORE'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => onViewAdditionalMarkets(event.event_id, 'team')}
                />
                :<></>}
                {competitions && competitions_expanded ?
                <View style={{ position:'absolute', bottom:0, right:0, ...view_styles.section, minWidth:300, maxHeight:220 }}>
                    <TouchableOpacity style={{ ...view_styles.section_header, padding:15, paddingRight:10, backgroundColor:Colors.highlights.highlight500 }} onPress={() => setCompetitionsExpanded(false)}>
                        <Icons.PickEmIcon size={16} color={Colors.shades.white} />
                        <Text style={{ flex:1, marginLeft:10 }} size={16} weight='bold' color={Colors.shades.white}>Select Competition</Text>
                        <Button
                            title='X'
                            backgroundColor='transparent'
                            title_color={Colors.utility.error}
                            disabled
                            title_size={16}
                            padding={0}
                            onPress={() => console.log('')}
                        />
                    </TouchableOpacity>
                    <ScrollView style={{flex:1}}>
                        <FlatList
                            data={competitions}
                            renderItem={renderCompetitions}
                            keyExtractor={(item) => item.competition_id.toString()}
                        />
                    </ScrollView>
                </View>
                :<></>}
            </View>
            :<></>}
            {expanded ?
            <View>
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <View style={{flex:1}}>
                        <LinearDiagnal label_size={12} label={'Hot Markets'} left_color={Colors.utility.warning} right_color={Colors.utility.error}/>
                    </View>
                    {loading ?
                        <ActivityIndicator style={{ paddingRight:20 }} size='small' color={Colors.brand.midnight} /> 
                    :
                    <Button
                        title='SEE ALL'
                        padding={15}
                        title_color={Colors.brand.electric}
                        onPress={() => onViewAdditionalMarkets(event.event_id, 'team')}
                    />
                    }
                </View>
                <FlatList
                    data={non_primary_markets}
                    renderItem={renderNonPrimaryMarkets}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => `${item.market_id}:${item.side_type}:${item.side_id}`}
                    horizontal
                />
            </View>
            :<></>}
        </View>
    )
}

export default React.memo(TeamEventMarket, (left, right) => {
    if(JSON.stringify(left) != JSON.stringify(right)){ return false }
    return true
})