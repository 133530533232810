import React, { useEffect, useState } from 'react';
import { View, ScrollView, Image, FlatList, ActivityIndicator } from "react-native"
import type { PollCampaignLeaderProps, PollCampaignProps, PublicPlayerProps } from '../../types';
import { PollCampaignApi } from '../api';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';

type CampaignLeaderboardProps = {
    poll_campaign:PollCampaignProps,
    view_mode: "short"|"full",
    height?:number,
    onShowFullLeaderboard?: () => void,
    onClose?:() => void
}

const CampaignLeaderboard = ({ poll_campaign, height, view_mode, onShowFullLeaderboard, onClose }:CampaignLeaderboardProps) => {
    const [ leaderboard_data, setLeaderData ] = useState<{
        loading:boolean,
        offset:number,
        leaders:PollCampaignLeaderProps[],
        players:PublicPlayerProps[],
        my_leader?: PollCampaignLeaderProps
    }>({
        loading:false,
        offset:0,
        leaders:[],
        players:[]
    })
    const { loading, leaders, players, my_leader, offset } = leaderboard_data;
    const me = players.find(p => p.player_id == my_leader?.player_id);

    let filtered_leaders = [ ...leaders ]
    if(view_mode == 'short'){
        filtered_leaders = filtered_leaders.sort((a,b) => a.place - b.place).slice(0,3);
    }

    useEffect(() => {
        getDataFromServer(0);
    },[poll_campaign.poll_campaign_id])

    const getDataFromServer = async(offset:number) => {
        setLeaderData({ ...leaderboard_data, loading:true })
        const ls = await PollCampaignApi.getLeadersByCampaignId(poll_campaign.poll_campaign_id, offset)
        const player_ids = ls.poll_campaign_leaders.map(l => l.player_id);
        if(ls.my_leaderboard){
            let my_id = player_ids.find(id => id == ls.my_leaderboard?.player_id);
            if(!my_id){ player_ids.push(ls.my_leaderboard.player_id) }
        }
        const ps = await PollCampaignApi.getPlayersByPlayerIds(player_ids);
        setLeaderData({
            ...leaderboard_data,
            players:ps,
            offset,
            loading: false,
            leaders: ls.poll_campaign_leaders,
            my_leader: ls.my_leaderboard
        })
    }

    const renderTrophy = (place:number) => {
        switch(place){
            case 1: return <Icons.TrophyIcon color={Colors.incentive.gold} size={16}/>
            case 2: return <Icons.TrophyIcon color={Colors.incentive.silver} size={16}/>
            case 3: return <Icons.TrophyIcon color={Colors.incentive.bronze} size={16}/>
            default: return <></>
        }
    }


    const renderLeaders = (data: {item:PollCampaignLeaderProps, index:number}) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){ return <></> }
        return (
            <View style={{ flexDirection:'row', alignItems:'center', margin:4, padding:10, borderRadius:22, backgroundColor:Colors.shades.white, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                <View nativeID="place" style={{ margin:5, height:20, width:20, justifyContent:'center', alignItems:'center', borderRadius:100, borderWidth:1, borderColor:Colors.brand.slate }}>
                    <Text size={10} color={Colors.brand.midnight} weight='bold' textAlign="center">{offset > 0 ? offset:''}{data.index+1}</Text>
                </View>
                <View nativeID="player" style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    <View nativeID="profile_pic" style={{ marginRight:10 }}>
                        <Image
                            source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1689262384/default-avatar_bbkn2t.png' }}
                            style={{ height:40, width:40, borderRadius:100 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1 }} nativeID="name">
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.username}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{data.item.winnings.toFixed(2)} Points</Text>
                    </View>
                    <View>
                        {renderTrophy(data.index+1)}
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={{ flex:1, height:height, backgroundColor:Colors.shades.shade100 }}>
            {my_leader && me ?
            <View nativeID="my_results">
                    <View style={{ flexDirection:'row', alignItems:'center', margin:4, padding:10, borderRadius:22, backgroundColor:Colors.shades.white }}>
                    <View nativeID="place" style={{ margin:5, height:20, width:20, justifyContent:'center', alignItems:'center', borderRadius:100, borderWidth:1, borderColor:Colors.brand.slate }}>
                        <Text size={10} color={Colors.brand.midnight} weight='bold' textAlign="center">{my_leader.place}</Text>
                    </View>
                    <View nativeID="player" style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                        <View nativeID="profile_pic" style={{ marginRight:10 }}>
                            <Image
                                source={{ uri: me.profile_pic && me.profile_pic != '' ? me.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1689262384/default-avatar_bbkn2t.png' }}
                                style={{ height:40, width:40, borderRadius:100 }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1 }} nativeID="name">
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>{me.username}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{my_leader.winnings.toFixed(2)} Points</Text>
                        </View>
                        <View>
                            {renderTrophy(my_leader.place)}
                        </View>
                    </View>
                </View>
            </View>
            :<></>}
            <ScrollView style={{flex:1}}>
                {loading ?
                    <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:10, alignSelf:'center' }} />
                :<></>}
                <FlatList
                    data={filtered_leaders}
                    renderItem={renderLeaders}
                    keyExtractor={((item) => item.player_id.toString())}
                />
            </ScrollView>
            {view_mode == 'full' ?
            <View style={{ flexDirection:'row', alignItems:'center' }}>
                {offset > 0 ?
                <Button
                    title='PREV'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset - 1)}
                />
                :<></>}
                <View style={{flex:1}} />
                <Button
                    title='NEXT'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset + 1)}
                />
            </View>
            :<></>}
            {view_mode == 'short' && onShowFullLeaderboard ?
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='See Full Leaderboard'
                    style={{ flex:1, alignSelf:'center' }}
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => onShowFullLeaderboard()}
                />
            </View>
            :<></>}
            {onClose ?
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='CLOSE'
                    style={{ flex:1 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CampaignLeaderboard