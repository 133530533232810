import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView, TouchableOpacity, View, Image, ActivityIndicator } from 'react-native';
import Colors from '../../constants/colors';
import { Button, Switch, Text } from '../../Components';
import SearchBox from '../../Components/SearchBox';
import { view_styles } from '../../constants/styles';
import type { AthleteProps, EventProps, MatchProps, TournamentProps } from '../../types';
import { ManageCompetitionMatchApi } from '../api';

type AthleteSelectorProps = {
    width:number,
    height:number,
    event?:EventProps,
    tournament?:TournamentProps,
    match?:MatchProps,
    selected_athletes: string[],
    filtered_athletes:string[],
    filtered_positions:string[],
    onSelectAthlete:(athlete:AthleteProps) => void,
    onDeselectAthlete:(athlete:AthleteProps) => void,
    onClose:() => void
}

const AthleteSelector = ({ width, height, event, tournament, match, filtered_athletes, selected_athletes, filtered_positions, onSelectAthlete, onDeselectAthlete, onClose }:AthleteSelectorProps) => {
    const [ search_value, setSearchValue ] = useState('');
    const [ selector_data, setSelectorData ] = useState<{
        loading:boolean,
        athletes:AthleteProps[],
        event_side:string
    }>({
        loading: false,
        athletes: [],
        event_side: 'away'
    })
    const { athletes, loading, event_side } = selector_data;
    
    let available_athletes = athletes.filter(a => `${a.first_name} ${a.last_name}`.toLowerCase().includes(search_value.toLowerCase()));
    if(filtered_athletes.length > 0){
        available_athletes = available_athletes.filter(a => filtered_athletes.map(a => a.toString()).includes(a.athlete_id.toString()))
    }
    if(available_athletes.length > 0){
        available_athletes = available_athletes.filter(a => filtered_positions.includes(a.position))
    }
    if(event && event_side){
        available_athletes = available_athletes.filter(a => a.team_id == event[event_side as keyof EventProps]?.team_id)
    }

    useEffect(() => {
        getAthletesFromServer();
    },[event, tournament, match])

    const getAthletesFromServer = async() => {
        setSelectorData({ ...selector_data, loading:true });
        let avail_athletes:AthleteProps[] = []
        if(event){
            let away_athletes = await ManageCompetitionMatchApi.getAthletesByTeamId(event.away_team_id);
            let home_athletes = await ManageCompetitionMatchApi.getAthletesByTeamId(event.home_team_id);
            avail_athletes = away_athletes.concat(home_athletes)
        }
        setSelectorData({
            ...selector_data,
            loading:false,
            athletes: avail_athletes
        })
    }

    const renderAthletes = (data: { item:AthleteProps, index:number }) => {
        const selected = selected_athletes.includes(data.item.athlete_id) ? true : false
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => selected ? onDeselectAthlete(data.item) : onSelectAthlete(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url ?? data.item.team?.image?.url ?? '' }}
                    style={{ height:30, width:30 }}
                    resizeMode='cover'
                />                
                <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.first_name} {data.item.last_name} ({data.item.position})</Text>
                    <Text style={{ marginTop:3 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.team?.market_name??''} {data.item.team?.name ?? ''}</Text>
                </View>
                <Switch 
                    switch_type="on_off"
                    value={selected}
                    onChange={() => {
                        if(!selected){ return onSelectAthlete(data.item) }
                        return onDeselectAthlete(data.item)
                    }}
                />
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ width, height, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>MANAGE ATHLETES</Text>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section_body }}>
                {event?.away && event?.home ?
                <View style={{ ...view_styles.body_row, margin:10, backgroundColor:Colors.shades.white, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100}}>
                    <Button
                        title={event.away.abbr}
                        title_color={event_side == 'away' ? Colors.shades.white : Colors.brand.midnight}
                        title_weight={event_side == 'away' ? 'bold' : 'regular'}
                        padding={15}
                        title_size={12}
                        style={{flex:1}}
                        borderRadiusOverride={{
                            borderTopLeftRadius: 22,
                            borderBottomLeftRadius:22,
                            borderTopRightRadius:0,
                            borderBottomRightRadius:0
                        }}
                        backgroundColor={event_side == 'away' ? Colors.brand.midnight : Colors.shades.white}
                        onPress={() => setSelectorData({ ...selector_data, event_side: 'away' })}
                    />
                    <Button
                        title={event.home.abbr}
                        style={{flex:1}}
                        title_size={12}
                        title_color={event_side == 'home' ? Colors.shades.white : Colors.brand.midnight}
                        title_weight={event_side == 'home' ? 'bold' : 'regular'}
                        padding={15}
                        borderRadiusOverride={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius:0,
                            borderTopRightRadius:22,
                            borderBottomRightRadius:22
                        }}
                        backgroundColor={event_side == 'home' ? Colors.brand.midnight : Colors.shades.white}
                        onPress={() => setSelectorData({ ...selector_data, event_side: 'home' })}
                    />
                    </View>
                    :<></>}
                    <View style={{ marginTop:0 }}>
                        <SearchBox 
                            onChange={(text) => setSearchValue(text)}
                            hide_search_button
                        />
                    </View>
                    {loading ?
                    <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:20, alignSelf:'center' }} />
                    :<></>}
                    <FlatList
                        data={available_athletes}
                        renderItem={renderAthletes}
                        keyExtractor={(item) => item.athlete_id.toString()}
                    />
                </View>
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='CLOSE'
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default AthleteSelector