import React, {  } from 'react';
import { FlatList, View, TouchableOpacity } from 'react-native';
import { Icons } from '../../Components';
import Colors from '../../constants/colors';

//Typecast a grade box object
type GradeBoxType = {
    min:number,
    max:number,
    success_color:string,
    fail_color:string,
    locked_color:string
}

//Stage the boxes and set the min / max and colors
const grade_boxes:GradeBoxType[] = [
    { min: 0, max: 79, success_color: 'rgba(125,205,133,0.2)', fail_color: 'rgba(213,87, 59, 0.2)', locked_color: Colors.shades.shade100 },
    { min: 80, max: 94, success_color: 'rgba(125,205,133,0.4)', fail_color: 'rgba(213,87, 59, 0.4)', locked_color: Colors.shades.shade100 },
    { min:95, max: 97.5, success_color: 'rgba(125,205,133,0.6)', fail_color: 'rgba(213,87, 59, 0.6)', locked_color: Colors.shades.shade100 },
    { min:97.6, max: 99.99, success_color: 'rgba(125,205,133,0.8)', fail_color: 'rgba(213,87, 59, 0)', locked_color: Colors.shades.shade100 },
    { min: 100, max: 200, success_color: 'rgba(125,205,133,1)', fail_color: 'rgba(213,87, 59, 0)', locked_color: Colors.shades.shade100 }
]

type OrderGradeBarProps = {
    mask?:boolean,
    grade?: number,
    view_type: 'card'|'book'|'market',
    onSelectGrade?:() => void
}

//OrderGradeBar React Component
const OrderGradeBar = ({ grade, view_type, mask, onSelectGrade }:OrderGradeBarProps ) => {
    
    //Get the player's role
    let premium = true
    if(mask){ premium = false }


    /**
     * Renders the grade boxes
     * @param data 
     * @returns 
     */
    const renderGradeBoxes = (data :{ item:GradeBoxType, index:number }) => {
        if(!grade && grade !== 0){ return <></> }
        //Check if the grade is greater than the minumum of the box
        const filled = grade >= data.item.min ? true : false
        const success = grade > 97.5 ? true : false
        const locked = !premium ? true : false
        //Render the box
        return (
            <View style={{ margin:2, height:15, width:15, borderRadius:4, backgroundColor:locked? data.item.locked_color : filled ? success ? data.item.success_color : data.item.fail_color : Colors.shades.shade100 }}>
            {locked ?
                <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                    <Icons.LockClosedIcon size={10} color={Colors.incentive.gold} />
                </View>
            :<></>}
            </View>
        )
    }

     /**
     * Renders the grade boxes
     * @param data 
     * @returns 
     */
      const renderGradeDashes = (data :{ item:GradeBoxType, index:number }) => {
        if(!grade && grade !== 0){ return <></> }
        //Check if the grade is greater than the minumum of the box
        const filled = grade >= data.item.min ? true : false
        const success = grade > 100 ? true : false
        const locked = !premium ? true : false

        //Render the box
        return (
            <View style={{ margin:1, height:4, width:15, borderRadius:4,  backgroundColor:locked? data.item.locked_color : filled ? success ? data.item.success_color : data.item.fail_color : Colors.shades.shade100 }} />
        )
    }

    if(!grade && grade !== 0){
        return (
            <></>
        )
    }

    if(view_type == 'market'){
        const success = grade > 100 ? true : false
        if(!premium){
            return (<></>)
        }
        return (
            <View style={{ height:3, backgroundColor:success?Colors.utility.success:Colors.utility.error, marginRight:3, marginLeft:3 }} />
        )
    }

    return (
        <View>
            <TouchableOpacity disabled={onSelectGrade ? false : true} onPress={() => onSelectGrade ? onSelectGrade() : console.log('')}>
                {view_type === 'card' ?
                <FlatList horizontal data={grade_boxes} renderItem={renderGradeBoxes} keyExtractor={(item) => item.min.toString()} />
                :view_type === 'book' ?
                <FlatList data={[ ...grade_boxes ].reverse()} renderItem={renderGradeDashes} keyExtractor={(item) => item.min.toString()} />
                :<></>}
            </TouchableOpacity>
        </View>
    )
}


export default OrderGradeBar