import React, { useEffect, useState } from 'react';
import { PollApi, PollCampaignApi } from './api';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import type { PollCampaignGoalProps, PollCampaignProps, PollProps, PollResponseProps } from '../types';
import Colors from '../constants/colors';
import CampaignHeader from './components/CampaignHeader';
import CampaignProgressBar from './components/CampaignProgressBar';
import PollQuestionsSection from './components/PollQuestionsSection';
import { Spring, Text } from '../Components';
//import PollCard from './components/PollCard';
import CampaignResult from './components/CampaignResult';
import CampaignLeaderboard from './components/CampaignLeaderboard';


type PollCampaignModuleProps = {
    poll_campaign_id:string,
    poll_id?:string,
    player_id?:string,
    height:number,
    onRequestAuthenticate: (auth_strategy_id?:string, company_id?:string) => void,
    onSharePollCampaign: (poll_campaign:PollCampaignProps) => void,
    onComplete:() => void
}
const PollCampaign = ({ height, poll_campaign_id, player_id }: PollCampaignModuleProps ) => {
    const [ module_data, setModuleData ] = useState<{
        loading: boolean,
        active_poll_id?:string,
        poll_campaign?:PollCampaignProps,
        polls:PollProps[],
        poll_campaign_goals:PollCampaignGoalProps[]
    }>({
        loading: false,
        polls: [],
        poll_campaign_goals:[]
    });
    const [ full_leaderboard, setFullLeaderboard ] = useState(false);
    const [ my_data ] = useState<{
        poll_responses:PollResponseProps[]
    }>({
        poll_responses: []
    });

    const { poll_campaign, polls, active_poll_id } = module_data;
    const active_poll = polls.find(p => p.poll_id == active_poll_id);
    const { poll_responses } = my_data;

    useEffect(() => {
        PollCampaignApi.setEnvironment();
        getDataFromServer();
    },[poll_campaign_id, player_id])

    const getDataFromServer = async() => {
        setModuleData({ ...module_data, loading:true });
        const pc = await PollCampaignApi.getPollCampaignById(poll_campaign_id);
        const ps_resp = await PollApi.getPollsByCampaignId(poll_campaign_id);

        setModuleData({
            ...module_data,
            poll_campaign: pc,
            polls: ps_resp.polls,
            poll_campaign_goals: ps_resp.poll_campaign_goals, 
            loading:false
        })
    }

    if(!poll_campaign){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator
                    style={{ padding:20, alignSelf:'center' }}
                    size='large'
                    color={Colors.brand.midnight}
                />
            </View>
        )
    }

    return (
        <View style={{ backgroundColor:Colors.shades.white, height }}>
            <CampaignHeader
                poll_campaign={poll_campaign}
                onSharePollCampaign={(pc) => console.log(pc)}
            />
            <ScrollView style={{ flex:1 }}>
                <View style={{ backgroundColor:Colors.shades.shade100 }}>
                    <CampaignProgressBar
                        poll_campaign={poll_campaign}
                        polls={polls}
                        active_poll='0'
                        onPollSelect={(p) => console.log(p)}
                        poll_responses={poll_responses}
                    />
                </View>
                <View nativeID='leaderboard'>
                    <CampaignResult
                        poll_campaign={poll_campaign}
                        onShowFullLeaderboard={() => setFullLeaderboard(true)}
                    />
                </View>
                <View nativeID='poll_questions'>
                        <PollQuestionsSection
                            poll_campaign={poll_campaign}
                            polls={polls}
                            poll_responses={poll_responses}
                            onSelectPoll={(p) => setModuleData({ ...module_data, active_poll_id: p.poll_id })}
                        />
                </View>
            </ScrollView>
            {active_poll ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'flex-end', backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                    style={{ maxHeight:height }}
                >
                    <Text>HEY</Text>
                </Spring>
            </View>
            :<></>}
            {full_leaderboard ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'flex-end', backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                    style={{ maxHeight:height }}
                >
                    <CampaignLeaderboard
                        height={height}
                        poll_campaign={poll_campaign}
                        view_mode='full'
                        onClose={() => setFullLeaderboard(false)}
                    />
                </Spring>
            </View>
            :<></>}
        </View>
    )
    

}

export default PollCampaign