import React, { useCallback } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { BestAvailableOrderProps, EventOrderStatProps, EventProps, MarketProps, MarketSideOptionProps, OrderProps, TradeProps } from '../../../types';
import { MarketButtonHelpers } from '../MarketButton/api';
import MarketButton from '../../components/MarketButton';
import { Icons, LinearDiagnal, Text } from '../../../Components';
import Colors from '../../../constants/colors';
import { view_styles } from '../../../constants/styles';
import { TeamEventMarketHelpers } from '../TeamEventMarket/api';
import { MarketComponentHelpers } from '../../api';

type EventMarketProps = {
    event:EventProps,
    width?:number,
    market:MarketProps,
    hide_market_banner?:boolean,
    latest_trades:TradeProps[],
    hide_liquidity?:boolean,
    best_available_orders:BestAvailableOrderProps[],
    event_order_stats:EventOrderStatProps[],
    show_grades?:boolean,
    default_price_view:'last_trade'|'best_available',
    onOrder:(order:OrderProps) => void,
    onView:(data: { event_id:string, event_type:string, market_id:string }) => void,
    onTradeLongPress:(trade:TradeProps) => void
}

const EventMarket = ({ width, event, hide_liquidity, market, latest_trades, best_available_orders, hide_market_banner, event_order_stats, show_grades, default_price_view, onOrder, onView, onTradeLongPress }:EventMarketProps) => {
    const { liquidity, open_order_count } = MarketComponentHelpers.getLiqudity(event_order_stats);
    const is_locked = MarketComponentHelpers.isEventMarketLocked(event, market.market_id);
    const renderSides = useCallback((data:{ item:MarketSideOptionProps, index:number, market:MarketProps }) => {
        const ba_order = best_available_orders.find(o => o.market_id == market.market_id && o.side == data.item.side && o.show);
        const side_details = MarketComponentHelpers.getTeamEventSideIds(event, market, data.item, undefined, undefined);
        if(!side_details){ return }
        let trade = MarketButtonHelpers.getVisibleTrade(default_price_view, data.market, data.item, latest_trades, best_available_orders, side_details.side_id, side_details.reversed_side_id); 
        if(!trade){
            trade = MarketButtonHelpers.getTradeFromMarket(market, event.event_id, 'team', data.item.side, data.item.id_source, side_details.side_id, side_details.reversed_side_id);
        }
        if(!trade){ return <></> }
        let side_label = event[data.item.side as keyof EventProps].abbr
        return (
            <View style={{ margin:2, height:50, width:75 }}>
                <MarketButton
                    side_option={data.item}
                    market={data.market}
                    side_label={side_label}
                    show_grades={show_grades}
                    locked={is_locked}
                    order={ba_order}
                    onLongPress={onTradeLongPress}
                    trade={trade}
                    onPress={(order) => {
                        const order_title = MarketComponentHelpers.getOrderTitleForTeamEvent(order, market, event);
                        if(!order_title){ return alert('Unable to process order') }
                        onOrder({ ...order, title: order_title })
                    }}
                />
            </View>
        )
    },[JSON.stringify(best_available_orders)])

    return (
        <View style={{ ...view_styles.section, flex:1, borderWidth:1, borderColor:Colors.shades.shade600, width, maxWidth:175 }}>
            {!hide_market_banner ?
             <LinearDiagnal 
                label={market.stat_label ?? market.type}
                label_size={14}
                style={{ borderTopLeftRadius:8, padding:5  }}
                left_color={Colors.incentive.gold}
                right_color={'#ebd197'}
            />
            :<></>}
            <View style={{ ...view_styles.section_body, flex:1, padding:0, justifyContent:'center', alignItems:'center' }}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Image
                        source={{ uri: event.away?.image?.url }}
                        style={{ height:50, width:50, marginRight:5 }}
                        resizeMode='cover'
                    />
                    <Text theme='body'>VS</Text>
                    <Image
                        source={{ uri: event.home?.image?.url }}
                        style={{ height:50, width:50, marginLeft:5 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ padding:10, paddingBottom:0 }}>
                    <Text weight='bold' size={14} color={Colors.brand.midnight} textAlign='center'>{event.event_title}</Text>
                    <Text style={{ marginTop:3 }} color={Colors.brand.midnight} weight='regular' textAlign='center' size={12}>{TeamEventMarketHelpers.getTimeDetail(event)}</Text>
                </View>
            </View>
            <View nativeID='market_sides' style={{ flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }}>
                {market.side_options.sort((a,b) => {
                    let a_t = a.side == market.trade_side ? 1 : 0
                    let b_t = b.side == market.trade_side ? 1 : 0
                    return a_t - b_t
                }).map((so, index) => {
                    return renderSides({ item:so, index, market })
                })}
                
            </View>
            {!hide_liquidity ?
            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, flexDirection:'row', alignItems:'center', borderBottomLeftRadius:8, borderBottomRightRadius:8 }}>
                <TouchableOpacity style={{ flex:1, flexDirection:'row' }} onPress={() => {
                    onView({
                        event_id: event.event_id, 
                        event_type: 'team', 
                        market_id: market.market_id
                    });
                }}>
                    <View style={{ backgroundColor:Colors.shades.white, height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                        <Icons.LiquidityIcon color={Colors.brand.midnight} size={30}/>
                    </View>
                    <View style={{ marginLeft:10 }}>
                        <Text theme='body_2'>Liquidity</Text>
                        <Text size={12} color={Colors.brand.electric} weight='semibold'>${liquidity.toFixed()} {open_order_count} Orders</Text>
                    </View>
                </TouchableOpacity>
            </View>
            :<></>}
        </View>
    )
}

export default EventMarket