import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { AthleteProps, BracketCompetitionProps, CompanyProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPayoutTypeProps, CompetitionPlayerBracketProps, CompetitionPlayerProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionResultTypeProps, CompetitionSeasonPlayerProps, CompetitionSeasonProps, CompetitionSeasonResultProps, CompetitionSummaryProps, CompetitionTypeProps, EventProps, ExternalPriceProps, LeagueProps, MarketProps, MatchProps, MyPlayerProps, PlayerBalanceProps, PlayerBracketProps, PlayerPickProps, PlayerReferralProps, PromoProps, PublicPlayerProps, SquaresCompetitionProps, TeamProps, TournamentProps, TradeProps } from "../../types";
import moment from "moment-mini";
import type { CompanyMemberProps } from "../../Authenticator/api/types";

let AUTH_SVC_API = ''
let TP_SVC_API = ''
let EVENT_SVC_API = ''
let MK_SVC_API = ''

export { ManageCompetitionApi, ManageCompetitionMarketApi, ManageCompetitionMatchApi, ManageCompetitionPlayerApi, ManageCompetitionResultApi, ManagePlayerPicksApi, ManageCompetitionHelpers }



const ManageCompetitionApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    },
    getMyAdminCompetition:async(offset:number, status?:'active'|'closed'):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/admin?offset=${offset}&status=${status}`)
        return resp.data.competitions
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues?status=active`)
        return resp.data.leagues
    },
    getMarkets: async():Promise<MarketProps[]> => {
        const resp = await axios.get(`${MK_SVC_API}/v1/markets/all`)
        return resp.data.markets
    },
    getLeagueById: async(league_id:string):Promise<LeagueProps> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues/league/${league_id}`)
        return resp.data.league
    },
    getCompetitionsByCompanyId: async(company_id:string, offset:number, status:'active'|'closed'):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/company/${company_id}?offset=${offset}&status=${status}`)
        return resp.data.competitions
    },
    getCompetitionById: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/competition/${competition_id}`)
        return resp.data.competition
    },
    getCompetitionMatchesByCompetitionId: async(competition_id:string):Promise<{competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}`)
        return { competition_matches: resp.data.competition_matches, competition_match_markets: resp.data.competition_match_markets }
    },
    getEnteredCompetitions: async():Promise<{competitions: CompetitionProps[], competition_players:CompetitionPlayerProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/entered`)
        return resp.data
    },
    getCompetitionsBySeasonId: async(competition_season_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/season/${competition_season_id}`)
        return resp.data.competitions
    },
    getMyActiveSquaresCompetitions:async():Promise<{ squares_competitions:SquaresCompetitionProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/squares/me/active`);
        return resp.data
    },
    getMyHistorySquaresCompetitions:async(offset:number):Promise<{ squares_competitions:SquaresCompetitionProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/squares/me/history?offset=${offset}`);
        return resp.data
    },
    getMyActiveCompetitions:async():Promise<{ competitions:CompetitionProps[], competition_players:CompetitionPlayerProps[], competition_records:CompetitionRecordProps[], competition_results:CompetitionResultProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/me/active`);
        return resp.data
    },
    getMyHistoryCompetitions:async(offset:number):Promise<{ competitions:CompetitionProps[], competition_players:CompetitionPlayerProps[], competition_records:CompetitionRecordProps[], competition_results:CompetitionResultProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/me/history?offset=${offset}`);
        return resp.data
    },
    getMyActiveSeasons:async():Promise<{ competition_seasons:CompetitionSeasonProps[], competition_season_players:CompetitionSeasonPlayerProps[], competition_season_results:CompetitionSeasonResultProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/me/active`);
        return resp.data
    },
    getMyHistorySeasons:async(offset:number):Promise<{ competition_seasons:CompetitionSeasonProps[], competition_season_players:CompetitionSeasonPlayerProps[], competition_season_results:CompetitionSeasonResultProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/me/history?offset=${offset}`);
        return resp.data
    },
    getMyActiveBracketCompetitions:async():Promise<{ bracket_competitions:BracketCompetitionProps[], competition_player_brackets:CompetitionPlayerBracketProps[], player_brackets:PlayerBracketProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/me/active`);
        return resp.data
    },
    getMyHistoryBracketCompetitions:async(offset:number):Promise<{ bracket_competitions:BracketCompetitionProps[], competition_player_brackets:CompetitionPlayerBracketProps[], player_brackets:PlayerBracketProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/me/history?offset=${offset}`);
        return resp.data
    },
    getPublicCompetitions: async(offset:number):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/all?offset=${offset}&status=scheduled`)
        return resp.data.competitions
    },
    getMyCompanies : async():Promise<{companies:CompanyProps[], company_members:CompanyMemberProps[]}> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/companies/me`)
        const { companies, company_members } = resp.data
        return { companies, company_members }
    },
    getActiveSponsoredCompetitions: async(sponsor_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/sponsored/${sponsor_id}`)
        return resp.data.competitions
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyReferralCode: async():Promise<{ player_referral:PlayerReferralProps, promo:PromoProps }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/me`)
        return resp.data
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
    getTemplateCompetitions: async():Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/templates`)
        return resp.data.competitions
    },
    createCompetition: async(company_id?:string, sponsor_id?:string, competition_name?:string, template_competition_id?:string, competition_season_id?:string):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/create`, { company_id, sponsor_id, competition_name, template_competition_id, competition_season_id })
        return resp.data.competition
    },
    updateCompetition: async(competition:CompetitionProps):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/update`, { competition })
        return resp.data.competition
    },
    searchPlayers: async(search_value:string, offset:number):Promise<PublicPlayerProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/search/value`, { search_value, offset })
        return resp.data.players
    },
    activateCompetition: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/activate`, { competition_id })
        return resp.data.competition
    },
    pauseCompetition: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/pause`, { competition_id })
        return resp.data.competition
    },
    resumeCompetition: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/resume`, { competition_id })
        return resp.data.competition
    },
    deleteCompetition: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/competition/delete`, { competition_id })
        return resp.data.competition
    },
    getCompetitionStatsByCompanyId: async(company_id:string):Promise<any> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/stats/company/${company_id}`)
        return resp.data
    },
    getCompetitionStatsByCompetitionId: async(competition_id:string):Promise<any> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/stats/competition/${competition_id}`)
        return resp.data.competition_stats
    },
    getCompetitionSummaries: async(competition_id:string):Promise<CompetitionSummaryProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/summaries/${competition_id}`)
        return resp.data.competition_summaries
    },
    exportCometitionLeaders : async(competition_id:string):Promise<string|undefined> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v2/competitions/export/competition/${competition_id}`)
            return resp.data
        } catch (e) {
            return undefined
        }
    },
    exportCometitionData : async(competition_id:string, event_id?:string, event_type?:string):Promise<string|undefined> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v2/competitions/export/matches/${competition_id}?event_id=${event_id}&event_type=${event_type}`)
            return resp.data
        } catch (e) {
            return undefined
        }
    }
}

const ManageCompetitionMarketApi = {
    getPrices : async(event_id:string, event_type:string):Promise<ExternalPriceProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/prices/latest/${event_id}/${event_type}`);
        return resp.data.prices
    },
    getLatestTradesByEventIds: async(event_ids:string[], event_type:string):Promise<TradeProps[]> => {
        if(event_ids.length == 0){ return [] }
        const resp = await axios.post(`${MK_SVC_API}/v1/trades/event/latest/bulk/get`, { event_ids, event_type })
        return resp.data.trades
    },
}

const ManageCompetitionMatchApi = {
    getCompetitionMatchesByCompetitionId: async(competition_id:string):Promise<{competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}`)
        return resp.data
    },
    getAthletesByTeamId: async(team_id:string):Promise<AthleteProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/athletes/team/${team_id}`)
        return resp.data.athletes
    },
    getEventsByDate: async(date:string, league_id:string):Promise<{ events: EventProps[], range_dates: string[] }> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/events/date/${date}?league_id=${league_id}`)
        const { events, range_dates } = resp.data
        return { events, range_dates }
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
        return resp.data.events
    },
    getMatchesByMatchIds: async(match_ids:string[]):Promise<MatchProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/matches/bulk/get`, { attribute: 'match_id', values: match_ids })
        return resp.data.matches
    },
    getAthletesByIds : async(athlete_ids:string[]):Promise<AthleteProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
        return resp.data.athletes
    },
    getTournamentsByTournamentIds: async(tournament_ids:string[]):Promise<TournamentProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/tournaments/bulk/get`, { attribute: 'event_id', values: tournament_ids })
        return resp.data.tournaments
    },
    getTeamsByIds: async(team_ids:string[]):Promise<TeamProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/teams/bulk/get`, { attribute:'team_id', values: team_ids })
        return resp.data.teams
    },
    getExoticsByIds : async(exotic_ids:string[]):Promise<any[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/exotics/bulk/get`, { attribute: 'exotic_id', values: exotic_ids })
        return resp.data.athletes
    },
    createCompetitionMatch: async(competition_id:string, event_id:string, event_type:string, match_type:string, market_id_override?:string, side_type_override?:string, side_id_override?:string):Promise<CompetitionMatchProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/match/create`, { competition_id, event_id, event_type, match_type, market_id_override, side_type_override, side_id_override })
        return resp.data.competition_match
    },
    updateCompetitionMatch: async(competition_match:CompetitionMatchProps):Promise<CompetitionMatchProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/match/update`, { competition_match })
        return resp.data.competition_match
    },
    deleteCompetitionMatch: async(competition_match_id:string):Promise<{ competition_match:CompetitionMatchProps, competition_match_markets:CompetitionMatchMarketProps[] }> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/match/delete`, { competition_match_id })
        return { competition_match: resp.data.competition_match, competition_match_markets:resp.data.competition_match_markets }
    },
    createCompetitionMatchMarkets: async(competition_match_markets:CompetitionMatchMarketProps[]):Promise<CompetitionMatchMarketProps[]> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/market/create`, { competition_match_markets })
        return resp.data.competition_match_markets
    },
    updateCompetitionMatchMarkets: async(competition_match_markets:CompetitionMatchMarketProps[]):Promise<CompetitionMatchMarketProps[]> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/market/update`, { competition_match_markets })
        return resp.data.competition_match_markets
    },
    deleteCompetitionMatchMarkets: async(competition_match_market_ids:string[]):Promise<CompetitionMatchMarketProps[]> => {
        if(competition_match_market_ids.length == 0){ return [] }
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/market/delete`, { competition_match_market_ids })
        return resp.data.competition_match_markets
    }
}

const ManageCompetitionPlayerApi = {
    getCompetitionPlayersByCompetition: async(competition_id:string, offset:number, status?:string):Promise<CompetitionPlayerProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/players/${competition_id}?offset=${offset}&status=${status}`)
        return resp.data.competition_players
    },
    joinCompetition: async(competition_player:CompetitionPlayerProps):Promise<{ competition:CompetitionProps, competition_player:CompetitionPlayerProps, competition_result:CompetitionResultProps, competition_record:CompetitionRecordProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/join`, { competition_player });
        return resp.data
    },
    leaveCompetition: async(competition_player:CompetitionPlayerProps):Promise<{competition:CompetitionProps, competition_player:CompetitionPlayerProps}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/leave`, { competition_player });
        return resp.data
    },
    rejectCompetition: async(competition_player:CompetitionPlayerProps):Promise<CompetitionPlayerProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/leave`, { competition_player });
        return resp.data.competition_player
    }
}


const ManagePlayerPicksApi = {
    getPlayerPicksByCompetition: async(competition_id:string, player_id:string, offset:number):Promise<PlayerPickProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/picks/competition/${competition_id}?player_id=${player_id}&offset=${offset}`);
        return resp.data.player_picks
    },
    createPlayerPick : async(player_pick:PlayerPickProps):Promise<{ player_pick: PlayerPickProps, competition_player:CompetitionPlayerProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/create`, { player_pick });
        return resp.data;
    },
    removePlayerPick: async(player_pick_id:string):Promise<{ player_pick: PlayerPickProps, competition_player:CompetitionPlayerProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/remove`, { player_pick_id })
        return resp.data
    }
}


const ManageCompetitionResultApi = {
    getResultsByCompetition: async(competition_id:string, offset:number, player_id_override?:string):Promise<{competition_results:CompetitionResultProps[], competition_records:CompetitionRecordProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/results/competition/${competition_id}?offset=${offset}&player_id=${player_id_override}`)
        return resp.data
    }
}

const ManageCompetitionHelpers = {
isCompetitionValid: (competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[], events:EventProps[], competition?:CompetitionProps) => {
    if(!competition){ return { valid:false, info:false, contest_settings:false, settings:false, contests:false } }
    let competition_type = competition_types.find(t => t.competition_type_id == competition.competition_type_id)
    let competition_result_type = competition_result_types.find(t => t.competition_result_type_id == competition.competition_result_type_id)
    if(!competition_type || !competition_result_type){ return { valid:false, info:false, contest_settings:false, settings:false, contests:false } }
    const competition_validity = {
        'info' : ManageCompetitionHelpers.isCompetitionInfoValid(competition),
        'contest_settings' : ManageCompetitionHelpers.isContestSettingsValid(competition, competition_type, competition_result_type),
        'settings': ManageCompetitionHelpers.isCompetitionSettingsValid(competition, competition_type, competition_result_type),
        'contests' : ManageCompetitionHelpers.isContestsValid(competition, competition_matches, competition_match_markets, events)
    }
    let valid = competition_validity.info && competition_validity.contest_settings && competition_validity.settings && competition_validity.contests ? true : false
    return { ...competition_validity, valid }
},
createCompetitionMatchFromEventId :(event_id:string, event_type:'team'):CompetitionMatchProps => {
    return {
        competition_match_id: '',
        competition_id: '',
        event_id,
        event_type,
        status: 'inprogress',
        create_datetime: '',
        last_update_datetime: '',
        match_type: 'event'
    }
},
createCompetitionMatchMarketFromTrade : (trade:TradeProps):CompetitionMatchMarketProps => {
    return {
        competition_match_market_id: '',
        competition_match_id: '',
        event_id: trade.event_id,
        event_type: trade.event_type,
        odds: trade.odds,
        side: trade.side,
        probability: trade.probability,
        create_datetime: '',
        last_update_datetime: '',
        market_id: trade.market_id,
        var_1: trade.var_1,
        side_id: trade.side_id,
        status: 'pending'
    }
},
/*
createEmptyCompetitionMatchMarkets :(competition_match_id:string, market:MarketProps, event:EventProps, side_type?:string, side_id?:string):CompetitionMatchMarketProps[] => {

    let cmms:CompetitionMatchMarketProps[] = []
    let id_source = //market.side_options[0].id_source

    

    market.side_options.map(so => {
        side_type = id_source
        if(id_source == 'side'){
            side_id = so.side
        } else {
            switch(id_source){
                case 'team':
                    if(market.level == 'event'){
                        side_id = event[so.side]?.team_id
                    } //Otherwise, we better be given an id!
                    break
                default: break
            }
        }

        if(!side_id){ return }

        cmms.push({
            competition_match_id,
            competition_match_market_id: '',
            market_id: market.market_id,
            side: so.side,
            side_id,
            side_type,
            create_datetime: '',
            last_update_datetime: '',
            var_1: 0,
            odds: 100,
            probability: 0.5,
            status: 'error'
        })
    })

    return cmms
},
*/
createEmptyCompetitionMatchMarket : (market:MarketProps, side_id:string):CompetitionMatchMarketProps => {
    return {
        competition_match_id: '',
        competition_match_market_id: '',
        market_id: market.market_id,
        side: market.trade_side,
        side_id,
        create_datetime: '',
        last_update_datetime: '',
        var_1: 0,
        odds: 100,
        probability: 0.5,
        status: 'error'
    }
},


/**
 * Takes an odds value and converts to a string with the + - and fixes to a supplied decimal value
 * @param odds 
 * @param decimal 
 * @returns 
 */
getOddsLabel :(odds:number, decimal?:number):string => {
    if(!odds){ return '' }
    if(odds > 0){ return `+${odds.toFixed(decimal??0)}` }
    return odds.toFixed(decimal??0)
},
getVar1Label : (var_1:number, market:MarketProps):string => {
    if(market.type === 'Spread'){ 
        if(var_1 >= 0){ return `+${var_1}` }
    }
    return `${var_1}`
},
calcProbabilityFromOdds : (odds:number) => {
    let prob;
    if(odds < 0) {
        odds = Math.abs(odds)
        prob = odds / (odds + 100) * 100
        return prob / 100
    } else {
        prob = 100 / (odds + 100) * 100
        return prob / 100
    }
},
calcAmericanOddsFromProbability : (probability:number) => {
    let p = probability*100; //Convert to whole number
    if (p === 100){ return -9999 }
    if(p === 0){ return 9999 }
    if(p === 50){ return 100 }
    let odds;
    if(p < 50){
        odds = ((100/(p/100))-100)
        return odds
    }
    odds = ((p/(1-(p/100)))*-1)
    return odds
},
getEmptyCompetition : ():CompetitionProps => {
    return {
        competition_id: '',
        competition_name:'',
        competition_description:'',
        buy_in: 0,
        auth_strategy_id: '1',
        admin_id:'',
        image: { url: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1635988385/be_notify_icon_nqplfc.png' },
        ticket_revenue:0,
        tickets_sold:0,
        available_tickets:10,
        market_ids: [],
        market_type: 'FOR_MONEY',
        status: 'scheduled',
        create_datetime:'',
        last_update_datetime: '',
        competition_type_id: '1',
        competition_result_type_id:'1',
        scheduled_datetime: '',
        entered: false,
        max_pick_count: 0,
        leagues: [],
        invite_only: false,
        length_type: 'event',
        competition_code: '',
        initial_balance: 1000,
        end_datetime:  moment().add(1, 'week')
    }
},
getStartTimeOptions :(competition:CompetitionProps, competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[], events:EventProps[]) => {
    if(!competition){ return [] }
    if(events.length == 0){ return [] }
    let included_events = events.filter(e => competition_matches.map(cm => cm.event_id.toString()).includes(e.event_id.toString())).sort((a,b) => moment(b.scheduled_datetime).unix() - moment(a.scheduled_datetime).unix())

    let start_time_options:{ start_time:string, label:string }[] = []
    let earliest_event = included_events[included_events.length - 1]
    if(!earliest_event){ return [] }
    start_time_options.push({ label:'Start time of the earliest contest', start_time: earliest_event.scheduled_datetime })
    let picks_available = competition.max_pick_count as number
    let done = false;
    included_events.map(e => {
        if(done){ return }
        let event_matches = competition_matches.filter(cm => cm.event_id == e.event_id)
        let event_markets = competition_match_markets.filter(cmm => event_matches.map(cm => cm.competition_match_id.toString()).includes(cmm.competition_match_id.toString()))
        let pick_options = event_markets.length / 2
        if(picks_available - pick_options <= 0){
            if(e.scheduled_datetime != earliest_event.scheduled_datetime){
                start_time_options.push({  label: 'Latest start time possible based on max picks', start_time: e.scheduled_datetime  })
            }
            done = true
        }
        picks_available -= pick_options
    })
  
    return start_time_options
},

isCompetitionInfoValid : (c:CompetitionProps) => {
    if(!c){ return false }
    if(!c.competition_name){ return false }
    if(!c.competition_description){ return false }
    //if(!c.competition_type_id){ return false }
    //if(!c.competition_result_type_id){ return false }
    //if(isNaN(c.buy_in) || c.buy_in < 0){ return false }
    //if(isNaN(c.available_tickets) || c.available_tickets < 1){ return false }
    return true
},
isCompetitionSettingsValid : (c:CompetitionProps, ct:CompetitionTypeProps, cr:CompetitionResultTypeProps) => {
    if(!c || !ct){ return false }
    if(isNaN(c.buy_in as number) || parseInt(c.buy_in as string) < 0){ return false }
    if(isNaN(c.available_tickets as number) || parseInt(c.available_tickets as string) < 1){ return false }
    if(cr.type == 'outpace' && !c.pacer_id){ return false }

    return true
},
isContestSettingsValid : (c:CompetitionProps, competition_type:CompetitionTypeProps, competition_result_type:CompetitionResultTypeProps) => {
    if(!c){ return false }
    if(isNaN(c.available_tickets as number)){ return false }
    if(isNaN(c.max_pick_count as number)){ return false }
    if(isNaN(c.initial_balance as number)){ return false }
    if(!competition_type){ return false }
    if(!competition_result_type){ return false }
    if(competition_type.type == 'pick' && parseFloat(c.max_pick_count as string) < 1){ return false }
    if(!c.scheduled_datetime){ return false }
    if(moment(c.scheduled_datetime).isBefore(moment())){ return false }
    if(c.length_type == 'time'){
        if(!c.end_datetime){ return false }
        if(moment(c.end_datetime).isBefore(moment())){ return false }
    }

    return true
    //This will be a beast
},
isContestsValid : (competition:CompetitionProps, competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[], events:EventProps[]) => {
    let valid = true
    competition_matches.map(cm => {
        if(valid == false){ return }
        let event = events.find(e => e.event_id == cm.event_id)
        if(!event){ valid = false; return }
        if(competition.status == 'pending' && moment().isAfter(event.scheduled_datetime)){ valid = false; return }
        let match_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == cm.competition_match_id)
        if(match_markets.length == 0){ valid = false }
        if(match_markets.length % 2 != 0){ valid = false } //INVALUD
    })
    return valid
},
formatPlace : (place:number):string => {
    switch(place){
        case 1: return `1st`
        case 2: return '2nd'
        case 3: return '3rd'
        case 4: return '4th'
        case 5: return '5th'
        case 6: return '6th'
        case 7: return '7th'
        case 8: return '8th'
        case 9: return '9th'
        default: return `${place}`
    }
}
}

export const FINALIZED_STATUSES = [
    'closed',
    'complete',
    'cancelled',
    'unnecessary',
    'postponed',
    'suspended'
]

export const IN_PROGRESS_STATUSES = [
    'inprogress',
    'halftime',
    'intermission'
]
