import React, { useEffect, useState } from "react"
import { View, FlatList, TouchableOpacity, Image, ActivityIndicator, ScrollView } from "react-native"
import { ManageCompetitionApi, ManageCompetitionHelpers } from "../api"
import Colors from "../../constants/colors"
import { view_styles } from "../../constants/styles"
import { Button, Icons, Text } from "../../Components"
import type { BracketCompetitionProps, CompetitionPlayerBracketProps, CompetitionPlayerProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionSeasonPlayerProps, CompetitionSeasonProps, CompetitionSeasonResultProps, PlayerBracketProps, SquaresCompetitionProps } from "../../types"

type AdminCompetitionListProps = {
    width:number,
    height?:number,
    onSelectCompetition:(competition:CompetitionProps) => void,
    onSelectSquaresCompetition:(squares_competition:SquaresCompetitionProps) => void,
    onSelectBracketCompetition:(bracket_competition:BracketCompetitionProps) => void,
    onClose: () => void,
    onCreateNew: (engagement_type:'competitions'|'brackets'|'squares') => void
}

const AdminCompetitionList = ({ width, height, onClose, onCreateNew, onSelectCompetition, onSelectBracketCompetition, onSelectSquaresCompetition }:AdminCompetitionListProps) => {
    const [ list_data, setData ] = useState<{
        loading: boolean,
        active_engagement: 'competitions'|'squares'|'brackets'
        active_tab:'active'|'closed',
        offset:number,
        admin_competitions:CompetitionProps[],
        competitions:CompetitionProps[],
        competition_players:CompetitionPlayerProps[],
        competition_records:CompetitionRecordProps[],
        competition_results:CompetitionResultProps[],
        competition_seasons:CompetitionSeasonProps[],
        competition_season_players:CompetitionSeasonPlayerProps[],
        competition_season_results:CompetitionSeasonResultProps[],
        bracket_competitions:BracketCompetitionProps[],
        competition_player_brackets:CompetitionPlayerBracketProps[],
        player_brackets:PlayerBracketProps[],
        squares_competitions:SquaresCompetitionProps[]
    }>({
        loading:false,
        offset:0,
        active_engagement: 'competitions',
        active_tab: 'active',
        competitions: [],
        competition_players:[],
        competition_records:[],
        competition_results:[],
        admin_competitions:[],
        competition_seasons: [],
        competition_season_players:[],
        competition_season_results:[],
        bracket_competitions: [],
        competition_player_brackets:[],
        player_brackets: [],
        squares_competitions: []
    })
    const { offset, active_tab, active_engagement, loading, competitions, admin_competitions, competition_results, bracket_competitions, squares_competitions, competition_player_brackets } = list_data;

    useEffect(() => {
        ManageCompetitionApi.setEnvironment();
        getDataFromServer(0)
    },[])

    useEffect(() => {
        getDataFromServer(0)
    },[active_engagement, active_tab])

    const getDataFromServer = async(offset:number) => {
        setData({ ...list_data, loading:true });
        switch(active_engagement){
            case 'competitions':
                const comps = await ManageCompetitionApi.getMyAdminCompetition(offset, active_tab);
                if(active_tab == 'active'){
                    const c_resp = await ManageCompetitionApi.getMyActiveCompetitions();
                    setData({ 
                        ...list_data,  
                        loading: false, 
                        admin_competitions: comps,
                        competitions: c_resp.competitions,
                        competition_players: c_resp.competition_players,
                        competition_records: c_resp.competition_records,
                        competition_results: c_resp.competition_results,
                        offset
                    })
                } else {
                    const c_resp = await ManageCompetitionApi.getMyHistoryCompetitions(offset);
                    setData({ 
                        ...list_data,  
                        loading: false, 
                        admin_competitions: comps,
                        competitions: c_resp.competitions,
                        competition_players: c_resp.competition_players,
                        competition_records: c_resp.competition_records,
                        competition_results: c_resp.competition_results,
                        offset
                    })
                }
                return
            case 'brackets':
                if(active_tab == 'active'){
                    const b_resp = await ManageCompetitionApi.getMyActiveBracketCompetitions();
                    setData({
                        ...list_data,
                        loading:false,
                        bracket_competitions: b_resp.bracket_competitions,
                        player_brackets: b_resp.player_brackets,
                        competition_player_brackets: b_resp.competition_player_brackets,
                        offset
                    })
                } else {
                    const b_resp = await ManageCompetitionApi.getMyHistoryBracketCompetitions(offset);
                    setData({
                        ...list_data,
                        loading:false,
                        bracket_competitions: b_resp.bracket_competitions,
                        player_brackets: b_resp.player_brackets,
                        competition_player_brackets: b_resp.competition_player_brackets,
                        offset
                    })
                }
                return
            case 'squares':
                if(active_tab == 'active'){
                    const s_resp = await ManageCompetitionApi.getMyActiveSquaresCompetitions();
                    setData({
                        ...list_data,
                        loading:false,
                        offset,
                        squares_competitions: s_resp.squares_competitions
                    })
                } else {
                    const s_resp = await ManageCompetitionApi.getMyHistorySquaresCompetitions(offset);
                    setData({
                        ...list_data,
                        loading:false,
                        offset,
                        squares_competitions: s_resp.squares_competitions
                    })
                }
                return
            default: return
        }  
    }

    const renderCompetitions = (data: { item:CompetitionProps, index:number }) => {
        const competition_result = competition_results.find(cr => cr.competition_id == data.item.competition_id);
        const cl = data.item.market_type == 'FOR_MONEY' ? '$' : 'E'
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:15, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectCompetition(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.competition_description}</Text>
                </View>
                {competition_result ?
                <View style={{ marginRight:10, marginLeft:10, justifyContent:'center' }}>
                    <Text size={12} color={Colors.brand.midnight} weight="bold" textAlign="center">{ManageCompetitionHelpers.formatPlace(competition_result.place)}</Text>
                    <Text size={12} color={Colors.brand.midnight} textAlign="center" style={{ marginTop:3 }}>{cl}{competition_result.winnings.toFixed(2)}</Text>
                </View>
                :<></>}
                <Icons.ChevronIcon direction='right' size={8} color={Colors.brand.midnight}/>
            </TouchableOpacity>
        )
    }

    const renderAdminCompetitions = (data: { item:CompetitionProps, index:number }) => {
        return (
            <TouchableOpacity style={{ borderRadius:8, backgroundColor:Colors.shades.white, width: width / 3, borderWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectCompetition(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:width/3*0.5, width:width/3, borderRadius:4, borderBottomRightRadius:0, borderBottomLeftRadius:0 }}
                    resizeMode="cover"
                />
                <View style={{ marginTop:5, padding:10 }}>
                    <Text size={14} textAlign="center" weight='bold'>{data.item.competition_name}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const renderSquares = (data: { item:SquaresCompetitionProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectSquaresCompetition(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.sq_comp_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.sq_comp_description}</Text>
                </View>
                <Icons.ChevronIcon direction='right' size={8} color={Colors.brand.midnight}/>
            </TouchableOpacity>
        )
    }

    const renderBrackets = (data: { item:BracketCompetitionProps, index:number }) => {
        const competition_player_bracket = competition_player_brackets.find(cpb => cpb.bracket_competition_id == data.item.bracket_competition_id);
        const cl = data.item.market_type == 'FOR_MONEY' ? '$' : 'E'
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectBracketCompetition(data.item)}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.competition_description}</Text>
                </View>
                {competition_player_bracket ?
                <View style={{ marginRight:10, marginLeft:10, justifyContent:'center' }}>
                    <Text size={12} color={Colors.brand.midnight} weight="bold" textAlign="center">{ManageCompetitionHelpers.formatPlace(competition_player_bracket.place)}</Text>
                    <Text size={12} color={Colors.brand.midnight} textAlign="center" style={{ marginTop:3 }}>{cl}{competition_player_bracket.winnings.toFixed(2)}</Text>
                </View>
                :<></>}
                <Icons.ChevronIcon direction='right' size={8} color={Colors.brand.midnight}/>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ maxWidth:width, minWidth:300, minHeight:400, height:height, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>MY ENGAGEMENTS</Text>
                    <Text style={{ marginTop:3 }} theme="body">Use the buttons below to see your active and past engagements (competitions, brackets, squares, etc)</Text>
                </View>
            </View>
            <View style={{ flexDirection:'row', margin:10, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100 }}>
                <Button
                    title="COMPETITIONS"
                    title_color={active_engagement == 'competitions' ? Colors.shades.white : Colors.brand.midnight}
                    backgroundColor={active_engagement == 'competitions' ? Colors.brand.midnight: Colors.shades.white}
                    title_weight={active_engagement == 'competitions' ? 'bold' : 'regular'}
                    borderRadius={0}
                    padding={10}
                    style={{ flex:1, borderTopLeftRadius:22, borderBottomLeftRadius:22 }}
                    onPress={() => setData({ ...list_data, active_engagement: 'competitions' })}
                />
                <Button
                    title="SQUARES"
                    title_color={active_engagement == 'squares' ? Colors.shades.white : Colors.brand.midnight}
                    backgroundColor={active_engagement == 'squares' ? Colors.brand.midnight: Colors.shades.white}
                    title_weight={active_engagement == 'squares' ? 'bold' : 'regular'}
                    borderRadius={0}
                    padding={10}
                    style={{ flex:1 }}
                    onPress={() => setData({ ...list_data, active_engagement: 'squares' })}
                />
                <Button
                    title="BRACKETS"
                    title_color={active_engagement == 'brackets' ? Colors.shades.white : Colors.brand.midnight}
                    backgroundColor={active_engagement == 'brackets' ? Colors.brand.midnight: Colors.shades.white}
                    title_weight={active_engagement == 'brackets' ? 'bold' : 'regular'}
                    borderRadius={0}
                    padding={10}
                    style={{ flex:1, borderTopRightRadius:22, borderBottomRightRadius:22 }}
                    onPress={() => setData({ ...list_data, active_engagement: 'brackets' })}
                />
            </View>
            <ScrollView style={{ ...view_styles.section_body, padding:0 }}>
                
                <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme="header">Showing {active_tab} {active_engagement}</Text>
                        <Text style={{ marginTop: 3}} theme="body">Use the toggle to the right to see active / closed engagements</Text>
                    </View>
                    <View style={{ flexDirection:'row' }}>
                        <Button
                            title="ACTIVE"
                            title_color={active_tab == 'active' ? Colors.shades.white : Colors.brand.midnight}
                            backgroundColor={active_tab == 'active' ? Colors.utility.success: Colors.shades.white}
                            title_weight={active_tab == 'active' ? 'bold' : 'regular'}
                            borderRadius={0}
                            style={{ borderTopLeftRadius:22, borderBottomLeftRadius:22 }}
                            padding={10}
                            onPress={() => setData({ ...list_data, active_tab: 'active' })}
                        />
                        <Button
                            title="CLOSED"
                            title_color={active_tab == 'closed' ? Colors.shades.white : Colors.brand.midnight}
                            backgroundColor={active_tab == 'closed' ? Colors.utility.error: Colors.shades.white}
                            title_weight={active_tab == 'closed' ? 'bold' : 'regular'}
                            borderRadius={0}
                            padding={10}
                            style={{ borderTopRightRadius:22, borderBottomRightRadius:22 }}
                            onPress={() => setData({ ...list_data, active_tab: 'closed' })}
                        />
                    </View>
                </View>
                {active_tab == 'closed' ?
                <View style={{ ...view_styles.section_footer }}>
                    {offset > 0 ?
                    <Button
                        title='PREV'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() =>  getDataFromServer(offset - 1)}
                    />
                    :<View/>}
                    <View style={{ flex:1 }} />
                    <Button
                        title='NEXT'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => getDataFromServer(offset + 1)}
                    />
                </View>
                :<></>}
                {loading ?
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                :<></>}
                {active_engagement == 'competitions' ?
                <View>
                    {admin_competitions.length > 0 ?
                    <View style={{ padding:10, backgroundColor:Colors.incentive.gold_faded }}>
                        <View style={{ padding:10 }}>
                            <Text theme="header_2">Admin Competitions</Text>
                            <Text style={{ marginTop:4 }} theme="body">These are the competitions you are an admin of</Text>
                        </View>
                        <FlatList
                            key='admin_competition'
                            data={admin_competitions}
                            horizontal
                            renderItem={renderAdminCompetitions}
                            keyExtractor={(item) => item.competition_id.toString()}
                        />
                    </View>
                    :<></>}
                    <FlatList
                        key={'my_competitions'}
                        data={competitions}
                        renderItem={renderCompetitions}
                        keyExtractor={(item) => item.competition_id.toString()}
                    />
                </View>
                :active_engagement == 'brackets' ?
                <FlatList
                    data={bracket_competitions}
                    renderItem={renderBrackets}
                    keyExtractor={(item) => item.bracket_competition_id.toString()}
                />
                :active_engagement == 'squares' ?
                <FlatList
                    data={squares_competitions}
                    renderItem={renderSquares}
                    keyExtractor={(item) => item.sq_comp_id.toString()}
                />
                :<></>}
            </ScrollView>


            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title="CREATE NEW"
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    padding={15}
                    onPress={() => onCreateNew(active_engagement)}
                />
                <Button
                    title="CLOSE"
                    title_color={Colors.shades.white}
                    style={{ flex:2, marginRight:8 }}
                    padding={15}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}


export default AdminCompetitionList