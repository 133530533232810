import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import type { PollCampaignProps } from '../../types';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

type CampaignHeaderProps = {
    poll_campaign:PollCampaignProps,
    onSharePollCampaign:(pc:PollCampaignProps) => void
}

const CampaignHeader = ({ poll_campaign, onSharePollCampaign }:CampaignHeaderProps) => {

    return (
        <View>
            <View nativeID="competition_header" style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View>
                    <Image
                        source={{ uri: poll_campaign.campaign_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                        style={{ height:50, width:50, borderRadius:4 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>{poll_campaign.name}</Text>
                </View>
                <TouchableOpacity style={{ paddingLeft:10 }} onPress={() => onSharePollCampaign(poll_campaign)}>
                    <Icons.ShareIcon color={Colors.brand.midnight} size={14} />
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default CampaignHeader