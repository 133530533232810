import TeamEventMarket from './components/TeamEventMarket';
import AthleteMarket from './components/AthleteMarket';
import EventMarket from './components/EventMarket';
import AthleteTournamentMarket from './components/AthleteTournamentMarket';

export default {
    TeamEventMarket,
    AthleteMarket,
    EventMarket,
    AthleteTournamentMarket
}