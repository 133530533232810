import { TouchableOpacity, View } from "react-native"
import React, { useState } from "react"
import type { PollCampaignProps } from "../../types"
import { Icons, Text } from "../../Components"
import Colors from '../../constants/colors';
import { view_styles } from "../../constants/styles"
import CampaignLeaderboard from "./CampaignLeaderboard"

type CampaignResultProps = {
    poll_campaign:PollCampaignProps,
    onShowFullLeaderboard?:() => void
}

const CampaignResult = ({ poll_campaign, onShowFullLeaderboard } : CampaignResultProps) => {
    const [ expanded, setExpanded ] = useState(true);

    return (
        <View style={{ flex:1, ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <Icons.PodiumIcon size={18} color={Colors.brand.midnight} />
                <View style={{flex:1, marginLeft:10}}>
                    <Text theme="header">Leaderboard</Text>
                    <Text style={{ marginTop:3 }} theme="body">Below are the leaderboard results!</Text>
                </View>
                <Icons.ChevronIcon size={8} color={Colors.brand.midnight} direction={expanded ? 'up' : 'down'} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:0, backgroundColor:Colors.shades.shade100 }}>
                <CampaignLeaderboard
                    view_mode="short"
                    poll_campaign={poll_campaign}
                    onShowFullLeaderboard={onShowFullLeaderboard}
                />
            </View>
            :<></>}
        </View>
    )
}


/*
<View style={{ borderRadius:22, backgroundColor:Colors.highlights.highlight200Faded, padding:10, marginTop:10 }}>
                    <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setPointsExpanded(!points_expanded)}>
                        <View style={{ flexDirection:'row', alignItems:'center', flex:1 }}>
                            <Icons.AlertIcon color={Colors.brand.midnight} size={14}/>
                            <Text style={{ marginLeft:5 }} size={14} color={Colors.brand.midnight} weight='semibold'>How are points calculated?</Text>
                        </View>
                        <Icons.ChevronIcon direction={points_expanded ? 'up' : 'down'} color={Colors.brand.midnight}/>
                    </TouchableOpacity>
                    {points_expanded ?
                    <View>
                        <Text size={12} color={Colors.brand.midnight} style={{ marginTop:10 }} weight='regular'>Points are calculated based on the total responses by all participants.  Each response is worth 1 point.  The responders that selected the winning option will split the total point pool.</Text>
                        <Text size={12} color={Colors.brand.midnight} style={{ marginTop:5 }} weight='regular'>Therefore, points earned will not be updated until the question is closed!</Text>
                    </View>
                    :<></>}
                </View>
*/

export default CampaignResult