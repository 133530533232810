import React, { useEffect, useState } from 'react';
import { ScrollView, View, ActivityIndicator, Image } from "react-native"
import { view_styles } from '../constants/styles';
import CompetitionInfoForm from './components/CompetitionInfoForm';
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSummaryProps, CompetitionTypeProps, EventProps, LeagueProps, MarketProps, MatchProps, MyPlayerProps, TeamProps, TournamentProps, TradeProps } from '../types';
import Colors from '../constants/colors';
import { ManageCompetitionApi, ManageCompetitionHelpers, ManageCompetitionMarketApi, ManageCompetitionMatchApi } from './api';
import CompetitionSettingsForm from './components/CompetitionSettingsForm';
import CompetitionContestsForm from './components/CompetitionContestsForm';
import ContestSelector from './components/ContestSelector';
import MarketSelector from './components/MarketSelector';
import AthleteSelector from './components/AthleteSelector';
import ContestSettingsForm from './components/ContestSettingsForm';
import CompetitionHeader from './components/CompetitionHeader';
import { Button, Text } from '../Components';
import PlayerSelector from './components/PlayerSelector';

type CompetitionManagerProps = {
    player_id?:string,
    competition_id:string,
    onClose:() => void
}

const CompetitionManager = ({ player_id, competition_id, onClose }:CompetitionManagerProps) => {
    const [ module_size, setModuleSize ] = useState({ height:0, width:0 });
    const [ action_loading, setActionLoading ] = useState(false);
    const [ show_athletes, setShowAthletes ] = useState<{
        contest_id?:string,
        contest_type?:string,
        market_id?:string,
        filtered_positions:string[]
    }>({
        filtered_positions: []
    });
    const [ show_pacers, setShowPacers ] = useState(false);
    const [ show_contests, setShowContests ] = useState<{
        visible: boolean
    }>({
        visible: false
    })
    const [ show_markets, setShowMarkets ] = useState<{
        contest_id?:string,
        contest_type?:string
    }>({})

    const [ competition_data, setCompetitionData ] = useState<{
        loaded:boolean,
        loading:boolean,
        player?:MyPlayerProps,
        competition?:CompetitionProps,
        draft_competition?:CompetitionProps,
        competition_result_types:CompetitionResultTypeProps[],
        competition_payout_types:CompetitionPayoutTypeProps[],
        competition_types:CompetitionTypeProps[],
        competition_matches:CompetitionMatchProps[],
        competition_match_markets:CompetitionMatchMarketProps[],
        competition_summaries: CompetitionSummaryProps[],
        trades:TradeProps[],
        markets:MarketProps[],
        leagues:LeagueProps[],
        events:EventProps[],
        tournaments:TournamentProps[],
        matches:MatchProps[],
        teams:TeamProps[],
        athletes:AthleteProps[]
        
    }>({
        loaded:false,
        loading:false,
        competition_result_types: [],
        competition_summaries:[],
        competition_types: [],
        competition_matches: [],
        competition_payout_types:[],
        competition_match_markets:[],
        markets: [],
        leagues:[],
        events: [],
        trades:[],
        tournaments: [],
        matches:[],
        teams:[],
        athletes:[]
    })
    const { contest_id, contest_type } = show_markets;
    const { loaded, player, competition, competition_summaries, competition_result_types, trades, competition_types, draft_competition, competition_matches, competition_match_markets, markets, leagues, events, tournaments, matches, teams, athletes } = competition_data;
    
    //Market Selector Stuff
    const showing_market_event = events.find(e => e.event_id == contest_id && contest_type == 'team');
    const showing_market_event_matches = competition_matches.filter(cm => cm.event_type == 'team' && cm.event_id == contest_id);
    const show_market_markets = competition_match_markets.filter(cmm => showing_market_event_matches.map(cm => cm.competition_match_id.toString()).includes(cmm.competition_match_id))
    const show_market_id_overrides = showing_market_event_matches.filter(cm => cm.market_id_override).map(cm => cm.market_id_override??'0');
    
    //AthleteSelectorStuff
    const select_athlete_matches = competition_matches.filter(cm => cm.event_id == show_athletes.contest_id && cm.event_type == show_athletes.contest_type && cm.market_id_override == show_athletes.market_id);
    const selected_athletes = select_athlete_matches.filter(m => m.side_type_override == 'athlete').map(m => m.side_id_override ?? '0');
    const select_athlete_event = events.find(e => show_athletes.contest_type == 'team' && e.event_id == show_athletes.contest_id);
    const select_athlete_positions = markets.find(m => m.market_id == show_athletes.market_id)?.supported_positions ?? []
    //Filter Athletes to those with trades
    let trade_athletes = [ ...new Set(trades.filter(t => t.market_type == 'FOR_MONEY' && t.market_id == show_athletes.market_id && t.side_type == 'athlete').map(t => t.side_id))]

    //Lets figure out if we can add / remove a primary market for all of them
    let primary_markets:{ market: MarketProps, included: boolean }[] = []
    events.map(e => {
        if(!e.supported_markets){ return }
        e.supported_markets.map(sm => {
            let market = markets.find(m => m.market_id == sm.market_id)
            if(!market?.primary_market){ return }
            let exists = primary_markets.find(pm => pm.market.market_id == sm.market_id)
            if(!exists){
                //check if this has been included or not!
                let included = competition_match_markets.find(cmm => cmm.market_id == market.market_id) ? true : false
                primary_markets.push({ market, included })
                
            }
        })
    })


    const competition_valid = ManageCompetitionHelpers.isCompetitionValid(competition_types, competition_result_types, competition_matches, competition_match_markets, events, competition)

    useEffect(() => {
        if(!loaded){ ManageCompetitionApi.setEnvironment() }
        getDataFromServer(competition_id);
    },[competition_id])


    const getDataFromServer = async(id:string) => {
        const c = await ManageCompetitionApi.getCompetitionById(id);
        const cm_resp = await ManageCompetitionMatchApi.getCompetitionMatchesByCompetitionId(id);
        const options = await ManageCompetitionApi.getCompetitionOptions()
        const lgs = await ManageCompetitionApi.getLeagues();
        const mks = await ManageCompetitionApi.getMarkets();
        const summaries = await ManageCompetitionApi.getCompetitionSummaries(id);

        const my_player = await ManageCompetitionApi.getMyDetails();

        //Get unique contests
        let event_ids = cm_resp.competition_matches.filter(cm => cm.event_type == 'team').map(cm => cm.event_id);
        let tournament_ids = cm_resp.competition_matches.filter(cm => cm.event_type == 'tournament').map(cm => cm.event_id);
        let match_ids = cm_resp.competition_matches.filter(cm => cm.event_type == 'match').map(cm => cm.event_id);
        //Get unique side ids
        let athlete_ids = cm_resp.competition_matches.filter(cm => cm.side_type_override == 'athlete').map(cm => cm.side_id_override ?? '0');
        let team_ids = cm_resp.competition_matches.filter(cm => cm.side_type_override == 'team').map(cm => cm.side_id_override ?? '0');

        let c_events:EventProps[] = []
        if(event_ids.length > 0){ c_events = await ManageCompetitionMatchApi.getEventsByEventIds(event_ids) }
        let e_trades = await ManageCompetitionMarketApi.getLatestTradesByEventIds(event_ids, 'team');
        let c_tournaments:TournamentProps[] = []
        if(tournament_ids.length > 0){ c_tournaments = await ManageCompetitionMatchApi.getTournamentsByTournamentIds(tournament_ids) }
        let t_trades = await ManageCompetitionMarketApi.getLatestTradesByEventIds(tournament_ids, 'tournamet');

        let c_matches:MatchProps[] = []
        if(match_ids.length > 0){ c_matches = await ManageCompetitionMatchApi.getMatchesByMatchIds(match_ids) }
        let m_trades = await ManageCompetitionMarketApi.getLatestTradesByEventIds(match_ids, 'match');

        let c_teams:TeamProps[] = []
        if(team_ids.length > 0){ c_teams = await ManageCompetitionMatchApi.getTeamsByIds(team_ids) }

        let c_athletes:AthleteProps[] = []
        if(athlete_ids.length > 0){ c_athletes = await ManageCompetitionMatchApi.getAthletesByIds(athlete_ids) }

        setCompetitionData({
            ...competition_data,
            loading: false,
            competition: c,
            leagues: lgs,
            player:my_player,
            competition_summaries: summaries,
            competition_types: options.competition_types,
            competition_payout_types: options.competition_payout_types,
            competition_result_types: options.competition_result_types,
            draft_competition: c,
            competition_matches: cm_resp.competition_matches,
            competition_match_markets: cm_resp.competition_match_markets,
            events: c_events,
            markets: mks,
            trades: e_trades.concat(m_trades).concat(t_trades),
            tournaments: c_tournaments,
            matches: c_matches,
            teams: c_teams,
            athletes: c_athletes,
        })
    }

    const handleRemovePrimaryMarket = async(market:MarketProps) => {
        setActionLoading(true);
        //Grab all markets
        let remove_markets = competition_match_markets.filter(cmm => cmm.market_id == market.market_id);
        await ManageCompetitionMatchApi.deleteCompetitionMatchMarkets(remove_markets.map(cmm => cmm.competition_match_market_id));
        setCompetitionData({
            ...competition_data,
            competition_match_markets: competition_match_markets.filter(cmm => cmm.market_id != market.market_id)
        })
        setActionLoading(false);
    }

    const handleAddPrimaryMarket = async(market:MarketProps) => {
        setActionLoading(true);
        let market_cmms:CompetitionMatchMarketProps[] = [];
        let primary_matches = competition_matches.filter(cm => !cm.market_id_override);
        primary_matches.map(cm => {
            let existing_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == cm.competition_match_id && cmm.market_id == market.market_id);
            if(existing_markets.length > 0){ return } //Dont do anything on that one!
            let market_trades = trades.filter(t => t.market_type == 'FOR_MONEY' && t.event_id == cm.event_id && t.event_type == cm.event_type && t.market_id == market.market_id);
            if(market_trades.length != 2){ console.log(`no trades for event ${cm.event_id}`); return } //This event doesnt have the trades
            let new_cmms:CompetitionMatchMarketProps[] = []
            market_trades.map(t => {
                new_cmms.push({ ...ManageCompetitionHelpers.createCompetitionMatchMarketFromTrade(t), competition_match_id: cm.competition_match_id })
            })
            market_cmms = market_cmms.concat(new_cmms)
        })
        market_cmms = await ManageCompetitionMatchApi.createCompetitionMatchMarkets(market_cmms);
        setCompetitionData({
            ...competition_data,
            competition_match_markets: competition_match_markets.filter(cmm => !market_cmms.find(ncmm => ncmm.competition_match_market_id == cmm.competition_match_market_id)).concat(market_cmms)
        })
        setActionLoading(false)
    }

    const handleSelectTeamEvents = async(new_events:EventProps[]) => {
        if(action_loading || !competition){ return }
        setActionLoading(true);
        let added_matches:CompetitionMatchProps[] = [];
        const addEvents = new_events.map(async e => {
            const cm = await ManageCompetitionMatchApi.createCompetitionMatch(competition_id, e.event_id, 'team', 'event', undefined, undefined, undefined);
            added_matches.push(cm)
        })
        await Promise.all(addEvents);
        const e_trades = await ManageCompetitionMarketApi.getLatestTradesByEventIds(new_events.map(e => e.event_id), 'team');
        setCompetitionData({
            ...competition_data,
            competition: { ...competition, scheduled_datetime: undefined },
            competition_matches: competition_matches.concat(added_matches),
            events: events.filter(e => !new_events.find(ne => ne.event_id == e.event_id)).concat(new_events),
            trades: trades.filter(t => !events.map(e => e.event_id.toString()).includes(t.event_id)).concat(e_trades)
        })
        setActionLoading(false)
    }

    const handleSelectTeamEvent = async(event:EventProps) => {
        if(action_loading || !competition){ return }
        setActionLoading(true);
        const cm = await ManageCompetitionMatchApi.createCompetitionMatch(competition_id, event.event_id, 'team', 'event', undefined, undefined, undefined);
        const e_trades = await ManageCompetitionMarketApi.getLatestTradesByEventIds([event.event_id], 'team');
        setCompetitionData({
            ...competition_data,
            competition: { ...competition, scheduled_datetime: undefined },
            competition_matches: competition_matches.concat(cm),
            events: events.filter(e => e.event_id != event.event_id).concat(event),
            trades: trades.filter(t => t.event_id != event.event_id).concat(e_trades)
        })
        setActionLoading(false);
    }

    const handleRemoveCompetitionMatch = async(competition_match_id:string) => {
        if(action_loading){ return }
        setActionLoading(true);
        await ManageCompetitionMatchApi.deleteCompetitionMatch(competition_match_id);
        setCompetitionData({
            ...competition_data,
            competition_matches: competition_matches.filter(cm => cm.competition_match_id != competition_match_id)
        })
        setActionLoading(false)
    }

    const handleRemoveCompetitionMatchMarkets = async(cmms:CompetitionMatchMarketProps[]) => {
        if(action_loading){ return }
        setActionLoading(true);
        await ManageCompetitionMatchApi.deleteCompetitionMatchMarkets(cmms.map(cmm => cmm.competition_match_market_id));
        setCompetitionData({
            ...competition_data,
            competition_match_markets: competition_match_markets.filter(cmm => !cmms.map(rmm => rmm.competition_match_market_id.toString()).includes(cmm.competition_match_market_id))
        })
        setActionLoading(false)
    }

    const handleDeselectTeamEvent = async(event:EventProps) => {
        const cms = competition_matches.filter(cm => cm.event_id == event.event_id && cm.event_type == 'team');
        const deleteCMS = cms.map(async cm => {
            await ManageCompetitionMatchApi.deleteCompetitionMatch(cm.competition_match_id);
        })
        await Promise.all(deleteCMS);
        setCompetitionData({
            ...competition_data,
            competition_matches: competition_matches.filter(cm => !cms.find(dcm => dcm.competition_match_id == cm.competition_match_id))
        })
    }
    const handleSelectAthlete = async(athlete:AthleteProps) => {
        if(!show_athletes?.contest_id || !show_athletes?.contest_type){ return }
        let athlete_trades = trades.filter(t => t.market_type == 'FOR_MONEY' && t.side_type == 'athlete' && t.side_id == athlete.athlete_id && t.market_id == show_athletes.market_id);
        if(athlete_trades.length != 2){ return alert('Unable to add') }
        
        if(action_loading){ return }
        setActionLoading(true);
        //First check if there is an existing market_id override without a side_id_override and add it!
        let existing_cm = competition_matches.find(cm => cm.event_id == show_athletes.contest_id && cm.event_type == show_athletes.contest_type && cm.market_id_override == show_athletes.market_id && !cm.side_id_override);
        if(existing_cm){
            existing_cm = await ManageCompetitionMatchApi.updateCompetitionMatch({ ...existing_cm, side_type_override: 'athlete', side_id_override: athlete.athlete_id });
        } else {
            existing_cm = await ManageCompetitionMatchApi.createCompetitionMatch(competition_id, show_athletes.contest_id, show_athletes.contest_type, 'athlete', show_athletes.market_id, 'athlete', athlete.athlete_id)
        }
        let new_cmms:CompetitionMatchMarketProps[] = []
        athlete_trades.map(t => {
            new_cmms.push({ ...ManageCompetitionHelpers.createCompetitionMatchMarketFromTrade(t), competition_match_id: existing_cm.competition_match_id })
        })
        new_cmms = await ManageCompetitionMatchApi.createCompetitionMatchMarkets(new_cmms);
        setCompetitionData({
            ...competition_data,
            athletes: athletes.concat(athlete),
            competition_matches: competition_matches.filter(cm => cm.competition_match_id != existing_cm.competition_match_id).concat(existing_cm),
            competition_match_markets:competition_match_markets.filter(cmm => !new_cmms.find(ncmm => ncmm.competition_match_market_id == cmm.competition_match_market_id)).concat(new_cmms)
        })
        setActionLoading(false)
    }

    const handleDeselectAthlete = async(athlete:AthleteProps) => {
        if(!show_athletes?.contest_id || !show_athletes?.contest_type || !show_athletes?.market_id){ return }
        let athlete_cm = competition_matches.find(cm => cm.market_id_override == show_athletes.market_id && cm.side_id_override == athlete.athlete_id)
        if(!athlete_cm){ return alert('Unable to remove') }
        if(action_loading){ return }
        setActionLoading(true);
        let cmms = competition_match_markets.filter(cmm => cmm.competition_match_id == athlete_cm.competition_match_id);
        await ManageCompetitionMatchApi.deleteCompetitionMatch(athlete_cm.competition_match_id);
        await ManageCompetitionMatchApi.deleteCompetitionMatchMarkets(cmms.map(cmm => cmm.competition_match_id))
        setCompetitionData({
            ...competition_data,
            competition_matches: competition_matches.filter(cm => cm.competition_match_id != athlete_cm.competition_match_id),
            competition_match_markets: competition_match_markets.filter(cmm => !cmms.map(rmm => rmm.competition_match_market_id.toString()).includes(cmm.competition_match_market_id))
        })
        setActionLoading(false)
    }

    const handleRemoveMarket = async(contest_id:string, contest_type:string, market:MarketProps) => {
        if(action_loading){ return }
        setActionLoading(true);
        let relevant_matches = competition_matches.filter(cm => cm.event_id == contest_id && cm.event_type == contest_type);
        //First remove all the non_primary if there are any
        let non_primary_matches = relevant_matches.filter(cm => cm.market_id_override == market.market_id);
        let matches_removed:string[] = []
        let markets_removed:string[] = []
        const rem_non_primary = non_primary_matches.map(async cm => {
            const del_response = await ManageCompetitionMatchApi.deleteCompetitionMatch(cm.competition_match_id);
            matches_removed = matches_removed.concat(del_response.competition_match.competition_match_id)
            markets_removed = markets_removed.concat(del_response.competition_match_markets.map(cmm => cmm.competition_match_market_id))
        })
        await Promise.all(rem_non_primary)

        //Ok now lets looks for the primary market one
        let primary_match = relevant_matches.find(cm => !cm.market_id_override)
        if(primary_match){
            let primary_cmms = competition_match_markets.filter(cmm => cmm.competition_match_id == primary_match.competition_match_id && cmm.market_id == market.market_id);
            
            await ManageCompetitionMatchApi.deleteCompetitionMatchMarkets(primary_cmms.map(cmm => cmm.competition_match_market_id));
            markets_removed = markets_removed.concat(primary_cmms.map(cmm => cmm.competition_match_market_id));
        }
        setCompetitionData({
            ...competition_data,
            competition_matches: competition_matches.filter(cm => !matches_removed.includes(cm.competition_match_id)),
            competition_match_markets: competition_match_markets.filter(cmm => !markets_removed.includes(cmm.competition_match_market_id))
        })
        setActionLoading(false);
    }

    const handleAddMarket = async(contest_id:string, contest_type:string, market:MarketProps) => {
        if(action_loading){ return }
        setActionLoading(true);
        //First check if this is a primary market
        if(market.primary_market){
            //Get the competition match that is available for it!
            let primary_match = competition_matches.find(cm => cm.event_type == contest_type && cm.event_id == contest_id && !cm.market_id_override);
            if(!primary_match){ 
                setActionLoading(false)
                return alert('Unable to add this market') 
            }
            //Now lets get the trades!!
            let market_trades = trades.filter(t => t.market_type == 'FOR_MONEY' && t.event_id == contest_id && t.event_type == contest_type && t.market_id == market.market_id)
            let cmms:CompetitionMatchMarketProps[] = []
            market_trades.map(t => {
                cmms.push({ ...ManageCompetitionHelpers.createCompetitionMatchMarketFromTrade(t), competition_match_id: primary_match.competition_match_id })
            })
            if(cmms.length != 2){ 
                setActionLoading(false)
                return alert('Unable to add this market') 
            }
            const new_cmms = await ManageCompetitionMatchApi.createCompetitionMatchMarkets(cmms);   
            setActionLoading(false);    
            return setCompetitionData({
                ...competition_data,
                competition_match_markets: competition_match_markets.filter(cmms => new_cmms.find(ncms => ncms.competition_match_market_id != cmms.competition_match_market_id)).concat(new_cmms)
            })
        }
        //If this is not a primary market - we need to add a market_id override for the competition match!!
        let existing_match = competition_matches.find(cm => cm.event_type == contest_type && cm.event_id == contest_id && cm.market_id_override == market.market_id);
        if(existing_match){ 
            setActionLoading(false)
            return alert('Already added this market!') 
        }
        const new_cm = await ManageCompetitionMatchApi.createCompetitionMatch(competition_id, contest_id, contest_type, market.level, market.market_id);
        setCompetitionData({
            ...competition_data,
            competition_matches: competition_matches.concat(new_cm)
        })
        setActionLoading(false)
    }


    if(!competition || !draft_competition || player_id != competition.admin_id){
        return (
            <View style={{flex:1}}>
                <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:20, alignSelf:'center' }} />
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }} onLayout={(ev) => {
                const { width, height } = ev.nativeEvent.layout;
                setModuleSize({ height, width })
            }}>
                <View style={{ ...view_styles.wrapper }}>
                    <CompetitionHeader
                        action_loading={action_loading}
                        width={module_size.width}
                        competition={competition}
                        competition_valid={competition_valid}
                        competition_summaries={competition_summaries}
                        onActivate={async() => {
                            setActionLoading(true);
                            const new_c = await ManageCompetitionApi.activateCompetition(competition_id)
                            setCompetitionData({ ...competition_data, competition: new_c })
                            setActionLoading(false);
                        }}
                        onPause={async() => {
                            setActionLoading(true);
                            const new_c = await ManageCompetitionApi.pauseCompetition(competition_id)
                            const new_summaries = await ManageCompetitionApi.getCompetitionSummaries(competition_id);
                            setCompetitionData({ ...competition_data, competition: new_c, competition_summaries: new_summaries })
                            setActionLoading(false);
                        }}
                        onDelete={async() => {
                            setActionLoading(true);
                            const new_c = await ManageCompetitionApi.deleteCompetition(competition_id);
                            setCompetitionData({ ...competition_data, competition: new_c })
                            setActionLoading(false);
                        }}
                        onResume={async() => {
                            setActionLoading(true);
                            const new_c = await ManageCompetitionApi.resumeCompetition(competition_id);
                            setCompetitionData({ ...competition_data, competition: new_c })
                            setActionLoading(false);

                        }}
                    />
                    <CompetitionInfoForm
                        is_valid={competition_valid.info}
                        competition={competition}
                        player={player}
                        width={module_size.width - 20}
                        onCompetitionUpdate={async(comp) => {
                            const new_c = await ManageCompetitionApi.updateCompetition(comp);
                            setCompetitionData({ ...competition_data, competition: new_c });
                        }}
                    />
                    <CompetitionSettingsForm
                        is_valid={competition_valid.settings}
                        competition={competition}
                        width={module_size.width - 20}
                        player={player}
                        onShowPacers={() => setShowPacers(true)}
                        competition_result_types={competition_result_types}
                        onCompetitionUpdate={async(comp) => {
                            const new_c = await ManageCompetitionApi.updateCompetition(comp);
                            setCompetitionData({ ...competition_data, competition: new_c });
                        }}
                    />
                    <CompetitionContestsForm
                        action_loading={action_loading}
                        is_valid={competition_valid.contests}
                        width={module_size.width - 20}
                        player={player}
                        competition={competition}
                        competition_matches={competition_matches}
                        competition_match_markets={competition_match_markets}
                        events={events}
                        primary_markets={primary_markets}
                        teams={teams}
                        athletes={athletes}
                        tournaments={tournaments}
                        onAddPrimaryMarket={(market) => handleAddPrimaryMarket(market)}
                        onRemovePrimaryMarket={(market) => handleRemovePrimaryMarket(market)}
                        matches={matches}
                        leagues={leagues}
                        markets={markets}
                        onDeleteMarket={(contest_id, contest_type, market) => handleRemoveMarket(contest_id, contest_type, market)}
                        onShowAthletes={(contest_id, contest_type, market_id) => setShowAthletes({ contest_id, contest_type, market_id, filtered_positions:[] })}
                        onShowMarkets={(contest_id, contest_type) => setShowMarkets({ contest_id, contest_type })}
                        onUpdateCompetition={async(comp) => {
                            const new_c = await ManageCompetitionApi.updateCompetition(comp);
                            setCompetitionData({ ...competition_data, competition: new_c });
                        }}
                        onShowContests={() => setShowContests({ visible:true })}
                        onSaveCompetitionMatch={() => console.log('SAVE EVENT')}
                        onDeleteCompetitionMatch={(competition_match_id) => handleRemoveCompetitionMatch(competition_match_id)}
                        onUpdateCompetitionMatch={() => console.log('UPDATE')}
                        onSaveCompetitionMatchMarkets={(smms) => console.log(smms)}
                        onDeleteCompetitionMatchMarkets={(cmms) => handleRemoveCompetitionMatchMarkets(cmms)}
                    />
                    <ContestSettingsForm
                        is_valid={competition_valid.contest_settings}
                        width={module_size.width - 20}
                        competition={competition}
                        competition_matches={competition_matches}
                        competition_match_markets={competition_match_markets}
                        competition_types={competition_types}
                        events={events}
                        onCompetitionUpdate={async(c) => {
                            const new_c = await ManageCompetitionApi.updateCompetition(c)
                            setCompetitionData({ ...competition_data, competition: new_c })
                        }}
                    />
                </View>
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='CLOSE'
                    style={{ flex:1 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    padding={15}
                    onPress={() => onClose()}

                />
            </View>
            {show_contests.visible ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <ContestSelector
                    action_loading={action_loading}
                    height={module_size.height}
                    width={module_size.width}
                    leagues={leagues}
                    onClose={() => setShowContests({ visible: false })}
                    onSelectTeamEvent={(event) => handleSelectTeamEvent(event)}
                    onSelectTeamEvents={(events) => handleSelectTeamEvents(events)}
                    onDeselectTeamEvent={(event) => handleDeselectTeamEvent(event)}
                    selected_team_events={events.filter(e => competition_matches.filter(cm => cm.event_type == 'team').map(cm => cm.event_id.toString()).includes(e.event_id.toString()))}
                />
            </View>
            :<></>}
            {show_athletes.contest_id ? 
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <AthleteSelector
                    width={module_size.width}
                    height={module_size.height}
                    event={select_athlete_event}
                    selected_athletes={selected_athletes}
                    filtered_positions={select_athlete_positions}
                    filtered_athletes={trade_athletes}
                    onSelectAthlete={(athlete) => handleSelectAthlete(athlete)}
                    onDeselectAthlete={(athlete) => handleDeselectAthlete(athlete)}
                    onClose={() => setShowAthletes({ filtered_positions: [] })}
                />
            </View>
            :<></>}
            {show_pacers ?
             <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
             <PlayerSelector
                 height={module_size.height}
                 width={module_size.width}
                 onClose={() => setShowPacers(false)}
                 onSelectPlayer={async(player) => {
                    setActionLoading(true);
                    const new_c = await ManageCompetitionApi.updateCompetition({ ...competition, pacer_id:player.player_id })
                    setCompetitionData({
                        ...competition_data,
                        competition: new_c
                    })
                    setActionLoading(false);
                    setShowPacers(false)
                 }}
             />
         </View>
            :<></>}
            {show_markets.contest_id && show_markets.contest_type && showing_market_event ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <MarketSelector
                    height={module_size.height}
                    width={module_size.width}
                    markets={markets.filter(m => showing_market_event.supported_markets?.map(m => m.market_id.toString()).includes(m.market_id.toString()))}
                    onClose={() => setShowMarkets({})}
                    onDeselectMarket={(market) => handleRemoveMarket(show_markets.contest_id ?? '0', show_markets.contest_type ?? 'team', market)}
                    onSelectMarket={(market) => handleAddMarket(show_markets.contest_id ?? '0', show_markets.contest_type ?? 'team', market)}
                    selected_markets={[ ...new Set(show_market_markets.map(cmm => cmm.market_id).concat(show_market_id_overrides))]}
                />
            </View>
            :<></>}
            {competition.status == 'inactive' ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                <View style={{ ...view_styles.section, width: '70%', backgroundColor:Colors.shades.white }}>
                    <View style={{ ...view_styles.section_header, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                        <Text theme='header'>Competition Has Been Deleted</Text>
                    </View>
                    <View style={{ ...view_styles.section_body, justifyContent:'center', alignItems:'center' }}>
                        <Image
                            source={{ uri: competition.image?.url }}
                            style={{ height:75, width: 75 }}
                            resizeMode='cover'
                        />
                        <Text theme='header_2'>{competition.competition_name}</Text>
                    </View>
                    <View style={{ ...view_styles.section_footer }}>
                        <Button
                            title='CLOSE'
                            style={{ flex:1 }}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.error}
                            padding={15}
                            onPress={() => onClose()}
                        />
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionManager