import { FlatList, TextInput, TouchableOpacity, View, Image } from "react-native"
import React, { useEffect, useState } from "react";
import type { CompetitionProps, CompetitionResultTypeProps, MyPlayerProps, PublicPlayerProps } from "../../types";
import { ManageCompetitionApi } from "../api";
import Colors from "../../constants/colors";
import { Button, Icons, Text } from "../../Components";
import { view_styles } from "../../constants/styles";
import DropDown from "../../Components/Dropdown";
import moment from "moment-mini";

type CompetitionSettingsFormProps = {
    player?: MyPlayerProps,
    competition: CompetitionProps,
    width:number,
    is_valid?:boolean,
    competition_result_types:CompetitionResultTypeProps[],
    onShowPacers:() => void,
    onCompetitionUpdate:(c:CompetitionProps) => void
}

const market_types = ['FOR_MONEY', 'FREE']

const CompetitionSettingsForm = ({ is_valid, player, width, competition, competition_result_types, onCompetitionUpdate, onShowPacers }: CompetitionSettingsFormProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ draft_competition, setDraftCompetition ] = useState<CompetitionProps | undefined>(undefined);
    //const [ player_selector_visible, setPlayerSelectorVisible ] = useState(false);
    const [ pacer, setPacer ] = useState<PublicPlayerProps | MyPlayerProps>();

    const competition_result_type = competition_result_types.find(crt => crt.competition_result_type_id == competition?.competition_result_type_id);

    useEffect(() => {
        setDraftCompetition(competition);
    },[competition])

    useEffect(() => {
        if(!competition?.pacer_id){ return }
        if(competition.pacer_id == pacer?.player_id){ return } //Already got him/her
        getPacerFromServer(competition.pacer_id)
    },[competition?.pacer_id])

    useEffect(() => {
        if(!competition_result_type){ return }
        if(competition_result_type.type == 'outpace' && !competition.pacer_id){
            setPacer(player)
            onCompetitionUpdate({ ...competition, pacer_id: player?.player_id })
        }
    },[competition_result_type?.type])

    const getPacerFromServer = async(player_id:string) => {
        const ps = await ManageCompetitionApi.getPlayersByPlayerIds([player_id])
        setPacer(ps[0])
    }

    const renderMarketTypes = (data:{ item:string, index:number }) => {
        if(!draft_competition){ return <></> }
        const selected = data.item == competition.market_type ? true : false
        return (
            <TouchableOpacity 
            disabled={competition.competition_season_id ? true : false}
            style={{ minWidth:75, margin:1, borderRadius:8, padding:10, backgroundColor:selected?Colors.brand.midnight:undefined }} onPress={() => onCompetitionUpdate({ ...draft_competition, market_type: data.item })}>
                <Text textAlign="center"  color={selected?Colors.shades.white:Colors.brand.midnight} weight={selected?'bold':'regular'} size={14}>{data.item == 'FOR_MONEY'?'USD':'EDGE COINS'}</Text>
            </TouchableOpacity>
        )
    }


    if(!competition || !draft_competition){ return <></> }
    const is_changed = JSON.stringify(competition) != JSON.stringify(draft_competition) ? true : false

    return (
        <View style={{ ...view_styles.section, flexGrow:1 }}>
            <TouchableOpacity style={{ ...view_styles.section_header, maxWidth: width }} onPress={() => setExpanded(!expanded)}>
                {is_valid ?
                <Icons.CheckCirlceIcon size={16} color={Colors.utility.success} />
                :
                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                }
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Competition Settings</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Manage the high level information of this competition</Text>
                </View>
                {is_changed ?
                <Button
                    title='SAVE'
                    padding={10}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onCompetitionUpdate(draft_competition)}
                />
                :
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                }
            </TouchableOpacity>
            {expanded ?
            <View nativeID="competition_settings" style={{ ...view_styles.section_body, maxWidth:width, backgroundColor:Colors.shades.shade100 }}>
                
                    
                <View nativeID="competition_currency" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width}}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header_2">Competition Currency</Text>
                        <Text style={{ marginTop:3 }} theme="body">Is the ticket price in real-money or edge coins?</Text>
                    </View>
                    <View style={{ justifyContent:'flex-end', margin: 5, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <FlatList
                            data={market_types}
                            renderItem={renderMarketTypes}
                            keyExtractor={(item) => item}
                            horizontal
                        />
                    </View>
                </View>

                <View nativeID="competition_payout" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width}}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header_2">Competition Payout Type</Text>
                        <Text style={{ marginTop:3 }} theme="body">{competition_result_type?competition_result_type.description:'Choose the type of payout for this competition'}</Text>
                    </View>
                    <View style={{ justifyContent:'flex-end', margin:5, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <DropDown
                            read_only={competition.competition_season_id ? true : false}
                            dropdown_options={[
                                {value: 'competition_result_type_id', eligible_options: competition_result_types.map(t => t.label)}
                            ]}
                            selected_value={competition_result_type?.label ?? ''}
                            onOptionSelect={(type) => {
                                const new_result_type = competition_result_types.find(crt => crt.label == type);
                                if(!new_result_type){ return }
                                onCompetitionUpdate({ ...draft_competition, competition_result_type_id: new_result_type.competition_result_type_id })
                            }}
                        />
                    </View>
                </View>
                {competition_result_type?.type == 'outpace' ?
                <TouchableOpacity 
                disabled={competition.competition_season_id ? true : false}
                style={{ backgroundColor:Colors.shades.white, borderRadius:8, padding:10 }} onPress={() => onShowPacers()}>
                    {pacer ?
                    <View style={{ ...view_styles.body_row }}>
                        <Icons.PacerCarIcon size={24} color={Colors.brand.midnight} />
                        <Image
                            source={{ uri: pacer.profile_pic && pacer.profile_pic != '' ? pacer.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                            style={{ height:40, width:40, borderRadius:4, marginLeft:10 }}
                            resizeMode="cover"
                        />
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme="header_2">@{pacer.username}</Text>
                            <Text style={{ marginTop:3 }} theme="body">Joined {moment(pacer.create_datetime).format('MMM YYYY')}</Text>
                        </View>
                        <Icons.ChevronIcon direction="right" color={Colors.brand.midnight} size={8} />
                    </View>
                    :
                    <View style={{ ...view_styles.body_row }}>
                        <Icons.AlertIcon size={20} color={Colors.utility.warning} />
                        <Image
                            source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                            style={{ height:40, width:40, borderRadius:4, marginLeft:10 }}
                            resizeMode="cover"
                        />
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme="header_2">Select A Pacer!</Text>
                        </View>
                        <Icons.ChevronIcon direction="right" color={Colors.brand.midnight} size={8} />
                    </View>
                    }
                </TouchableOpacity>
                :<></>}
                <View nativeID="buy_in" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width}}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header_2">Ticket Price</Text>
                        <Text style={{ marginTop:3 }} theme="body">Set the amount required to purchase a ticket to participate</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, textAlign:'center', margin:5, width:100, backgroundColor:Colors.shades.white }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, buy_in: text })}
                        placeholder="0"
                        editable={competition.competition_season_id ? false : true}
                        value={draft_competition.buy_in as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                {player?.role == 'admin' ?
                <View nativeID="buy_in" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width}}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header_2">Guaranteed Payout</Text>
                        <Text style={{ marginTop:3 }} theme="body">ADMIN ONLY - How much is guaranteed to be paid out?</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, textAlign:'center', margin:5, width:100, backgroundColor:Colors.shades.white }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, guaranteed_payout: text })}
                        placeholder="0"
                        editable={competition.competition_season_id ? false : true}
                        value={draft_competition.guaranteed_payout as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                    
                    <View nativeID="Max Tickets" style={{ flexDirection:'row', flexWrap:'wrap', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                        <View style={{ flex:1, marginRight:5 }}>
                            <Text theme="header_2">Max Tickets</Text>
                            <Text style={{ marginTop:3 }} theme="body">Maximum number of tickets that can be sold</Text>
                        </View>
                        <TextInput
                            style={{ ...view_styles.input, textAlign:'center', width:100, backgroundColor:Colors.shades.white }}
                            onChangeText={(text) => setDraftCompetition({ ...draft_competition, available_tickets: text })}
                            placeholder="100"
                            editable={competition.competition_season_id ? false : true}
                            value={draft_competition.available_tickets as string}
                            placeholderTextColor={Colors.brand.slate}
                        />
                    </View>
                </View>
            :<></>}
        </View>
    )
}

export default CompetitionSettingsForm