import  { useState, useMemo, useEffect } from "react";

export const useIsInViewport = (ref:any) => {
    const [ isIntersecting, setIsIntersecting ] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) => {
            if(!entry){ return }
            setIsIntersecting(entry.isIntersecting)
        }
        ),
      [],
    );
  
    useEffect(() => {
      if(!ref?.current){ return }
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };

    }, [ref, observer]);
    return isIntersecting;
  }